import React from 'react';

import {TOGGLE_LINK_COMMAND} from '@lexical/link';

import { LexicalEditor, RangeSelection } from 'lexical';

import { TextField } from "@mui/material";

export type LinkProps = {
    editor: LexicalEditor,
    linkUrl: string,
    lastSelection: RangeSelection | null,
    linkTitle?: string,
    setLinkUrl: (value: string) => void,
}
  
export function ExternalLinkEditorComponent({editor, linkUrl, lastSelection, setLinkUrl } : LinkProps) {
  const saveUrl = () => {
    if (lastSelection !== null && linkUrl !== "") {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
    }
  }
  return (
    <TextField
        autoComplete="off"
        placeholder="https://example.com/"
        value={linkUrl}
        aria-label='External link input'
        type="text"
        fullWidth
        className="link-input"
        onBlur={() => saveUrl()}
        onChange={(event) => {
          setLinkUrl(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            saveUrl();
          }
        }}
      />
    );
  }