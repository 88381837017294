import { filtersSelector } from "@app-redux/selectors";
import { SortNumber, updateSortFilter } from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { SEARCH_FOR, SORT_BY, SORT_TYPE } from "@constants/index";
import CheckIcon from "@mui/icons-material/Check";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, ListItemIcon } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import styles from "./styles.module.css";

const icon = (
  <ListItemIcon className={styles.icon}>
    <CheckIcon color="inherit" />
  </ListItemIcon>
);

export const SortMenu = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const {
    sort_type,
    sort_by,
    search_term,
    search_for,
    categories_values = [],
  } = useAppSelector(filtersSelector);

  const showRelevance = search_term || categories_values.length > 0;
  const selectByRelevance = search_for === SEARCH_FOR.relevance;
  const isSortNotSelected = !sort_type && !sort_by;
  const defaultSortByRelevance = isSortNotSelected && showRelevance;
  const defaultSortByName = isSortNotSelected && !showRelevance;

  const selectedNameAsc =
    (sort_type === SORT_TYPE.asc && sort_by === SORT_BY.name) ||
    defaultSortByName;
  const selectedNameDesc =
    sort_type === SORT_TYPE.desc && sort_by === SORT_BY.name;
  const selectedNewest =
    sort_type === SORT_TYPE.desc && sort_by === SORT_BY.updatedAt;
  const selectedOldest =
    sort_type === SORT_TYPE.asc && sort_by === SORT_BY.updatedAt;

  const sortType = (sortTypeNumber: SortNumber, selected: boolean) => {
    if (!selected) {
      dispatch(updateSortFilter(sortTypeNumber));
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="Sorting menu"
        color="inherit"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
        aria-controls={openMenu ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        {showRelevance && (
          <MenuItem
            divider
            selected={selectByRelevance}
            onClick={() => sortType(5, selectByRelevance)}
          >
            {(selectByRelevance || defaultSortByRelevance) && icon} By relevance
          </MenuItem>
        )}
        <MenuItem
          divider
          selected={selectedNameAsc}
          onClick={() => sortType(1, selectedNameAsc)}
        >
          {selectedNameAsc && icon}A – Z
        </MenuItem>
        <MenuItem
          divider
          selected={selectedNameDesc}
          onClick={() => sortType(2, selectedNameDesc)}
        >
          {selectedNameDesc && icon}Z – A
        </MenuItem>
        <MenuItem
          divider
          selected={selectedNewest}
          onClick={() => sortType(3, selectedNewest)}
        >
          {selectedNewest && icon} Newest – Oldest
        </MenuItem>
        <MenuItem
          selected={selectedOldest}
          onClick={() => sortType(4, selectedOldest)}
        >
          {selectedOldest && icon}Oldest – Newest
        </MenuItem>
      </Menu>
    </div>
  );
};
