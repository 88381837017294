import { ROUTER_PATHS } from "@constants/index";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";
import { Link as RouteLink } from "react-router-dom";

type BreadcrumbsProps = {
  pageName: string;
  width: string;
};

export const Breadcrumbs = ({ pageName, width }: BreadcrumbsProps) => (
  <MuiBreadcrumbs
    separator={<ArrowForwardIcon />}
    aria-label="breadcrumb"
    sx={{ margin: "10px auto 10px auto", width, paddingLeft: "12px" }}
  >
    <Button 
      component={RouteLink}
      to={ROUTER_PATHS.main}
      color="secondary"
      aria-label="Go to main page"
    >
      Main
    </Button>
    <Typography>{pageName}</Typography>
  </MuiBreadcrumbs>
);
