import {
  addProfileOnStart,
  setSelectedProfile,
  updateProfileAndListOrder,
} from "@app-redux/slices/profileListSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProfileCreateParams,
  ProfileCreateRes,
  ProfileUpdateRes,
  apiDeleteProfile,
  apiGetProfile,
  apiPatchProfile,
  apiPostProfile,
} from "../../api/profile";
import type { ProfileRes } from "../slices/profileSlice";
import { setProfile, updateProfile } from "../slices/profileSlice";
import { deleteProfile } from "./profileListThunk";

export const getProfile = createAsyncThunk<ProfileRes, string>(
  "profile/get",
  async (id, thunkAPI) => {
    try {
      const data = await apiGetProfile(id);
      thunkAPI.dispatch(setProfile(data));
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);

export type ProfileNewDataObject = {
  data: Omit<ProfileCreateParams, "page_content">;
  content: ProfileCreateParams["page_content"];
};

export const createProfile = createAsyncThunk<
  ProfileCreateRes,
  ProfileNewDataObject
>("profile/create", async ({ data, content }, thunkAPI) => {
  try {
    const res = await apiPostProfile({
      ...data,
      page_content: content,
    });

    if (res?.profile_id) {
      thunkAPI.dispatch(
        setSelectedProfile({
          id: res.profile_id as string,
          index: 0,
        })
      );
      const { raw_page_content, ...rest } = data;
      thunkAPI.dispatch(
        addProfileOnStart({
          ...rest,
          id: res.profile_id as string,
          image_url: res.image_url,
        })
      );
    }

    return res;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
  }
});

export const updateProfileData = createAsyncThunk<
  ProfileUpdateRes,
  { id: string; newDataObj: ProfileNewDataObject }
>(
  "profile/update",
  async (
    {
      newDataObj: {
        data: { image, ...rest },
        content,
      },
      id,
    },
    thunkAPI
  ) => {
    try {
      const res = await apiPatchProfile({
        params: { ...rest, image, page_content: content },
        id,
      });
      const storeNewData = {
        ...rest,
        ...(content ? { content } : {}),
        id,
        ...(image === "" ? { image_url: "" } : {}),
        ...(res ? { image_url: res } : {}),
      };
      await thunkAPI.dispatch(updateProfile({ params: storeNewData, id }));
      const { content: c, raw_page_content, ...restData } = storeNewData;
      await thunkAPI.dispatch(updateProfileAndListOrder({ data: restData }));
      return res;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);

export const deleteProfileThunk = createAsyncThunk<void, string>(
  "profile/delete",
  async (id, thunkAPI) => {
    try {
      await apiDeleteProfile(id);
      thunkAPI.dispatch(deleteProfile(id));
      return;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);
