const styles = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minHeight: "56px",
      },
    },
  },
};

export default styles;
