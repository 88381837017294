import { categorySelector } from "@app-redux/selectors";
import { CategoryItem } from "@app-redux/slices/categorySlice";
import { useAppSelector } from "@app-redux/store";
import { ChipGroup } from "@components/ChipGroup";
import { CountrySelect } from "@components/CountrySelect";
import { RepSelect } from "@components/RepSelect";
import { MultiSelect } from "@components/MultiSelect";
import { ErrorMessage } from "@components/ErrorMessage";
import { Stack, TextField } from "@mui/material";
import { FormParams } from "@pages/Profile";
import React from "react";
import { Controller } from "react-hook-form";
import { Control, FieldErrors } from "react-hook-form/dist/types";
import styles from "./styles.module.css";

const TEXT_INPUT_PROPS = {
  maxLength: 300,
};

const getOptions = (category: CategoryItem[]) =>
  category.map(({ id, name }) => ({ value: id, title: name }));

const PreContentForm = ({
  control,
  errors,
}: {
  control: Control<FormParams, any>;
  errors: FieldErrors<FormParams>;
}) => {
  const { data: categoryList } = useAppSelector(categorySelector);

  const onChipClick = (
    id: string,
    values: string[],
    callback: (ids: string[]) => void
  ) => {
    let newValue;
    if (values.includes(id)) {
      newValue = values.filter((v) => v !== id);
    } else {
      newValue = [...values, id];
    }
    callback(newValue);
  };
  const rolesCategoryList = categoryList.find((c) => c.name === "Role");
  const marketsCategoryList = categoryList.find((c) => c.name === "Market");
  const countriesCategoryList = categoryList.find((c) => c.name === "Country");
  const repsCategoryList = categoryList.find((c) => c.name === "Rep");
  return (
    <Stack direction="column" spacing="6px" className={styles.preContentForm}>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>
          Name <sup>*</sup>
        </div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                type="text"
                fullWidth
                className={styles.input}
                placeholder="Name and Surname"
                autoComplete="off"
                autoFocus
                error={!!errors.name}
                helperText={<ErrorMessage message={errors.name?.message}/>}
                inputProps={TEXT_INPUT_PROPS}
                {...field}
              />
            )}
          />
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>Role</div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="roles_ids"
            render={({ field }) => (
              <MultiSelect
                showItemsCount={rolesCategoryList?.items?.length || 0}
                className="w100"
                label="Choose Role(s)"
                options={getOptions(rolesCategoryList?.items || [])}
                {...field}
              />
            )}
          />
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>Market</div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="markets_ids"
            render={({ field }) => (
              <ChipGroup
                options={getOptions(marketsCategoryList?.items || [])}
                value={field.value}
                onChange={(id) =>
                  onChipClick(id, field.value || [], field.onChange)
                }
              />
            )}
          />
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>Country</div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="locations_ids"
            render={({ field }) => (
              <CountrySelect
                options={getOptions(countriesCategoryList?.items || [])}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>Lighting Vendor</div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="vendor"
            render={({ field }) => (
              <TextField
                autoComplete="off"
                type="text"
                className={styles.input}
                placeholder="Favorite Lighting Vendor(s)"
                fullWidth
                inputProps={TEXT_INPUT_PROPS}
                {...field}
              />
            )}
          />
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" className="w100">
        <div className={styles.formItemText}>Rep</div>
        <div className={styles.formItemInput}>
          <Controller
            control={control}
            name="reps_ids"
            render={({ field }) => (
              <RepSelect
                options={getOptions(repsCategoryList?.items || [])}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default PreContentForm;
