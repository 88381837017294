import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SEARCH_FOR, SORT_BY, SORT_TYPE } from "../../constants";

export type SortType = (typeof SORT_TYPE)[keyof typeof SORT_TYPE];
export type SortBy = (typeof SORT_BY)[keyof typeof SORT_BY];
export type SearchFor = (typeof SEARCH_FOR)[keyof typeof SEARCH_FOR];
export type SortNumber = 1 | 2 | 3 | 4 | 5;

export type Filter = {
  categories_values?: string[];
  search_term?: string;
  sort_type?: SortType;
  sort_by?: SortBy;
  search_for?: SearchFor;
  page?: number;
};

export type FilterState = {
  category: Filter;
};

const initialState: FilterState = { category: {} };

export const filtersSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setPageNumFilter: (
      state: Draft<FilterState>,
      { payload }: PayloadAction<number>
    ): void => {
      if (!payload) {
        delete state.category.page;
      } else {
        state.category.page = payload;
      }
    },
    setSearchFilter: (
      state: Draft<FilterState>,
      { payload }: PayloadAction<string>
    ): void => {
      if (!payload) {
        delete state.category.search_term;
        delete state.category.sort_type;
        delete state.category.sort_by;
        delete state.category.search_for;
      } else {
        state.category.search_term = payload;
        state.category.search_for = SEARCH_FOR.relevance;
        delete state.category.sort_type;
        delete state.category.sort_by;
      }
      delete state.category.page;
    },
    updateSortFilter: (
      state: Draft<FilterState>,
      { payload }: PayloadAction<SortNumber | undefined>
    ): void => {
      switch (payload) {
        case 1:
          state.category.sort_type = SORT_TYPE.asc;
          state.category.sort_by = SORT_BY.name;
          delete state.category.search_for;
          break;
        case 2:
          state.category.sort_type = SORT_TYPE.desc;
          state.category.sort_by = SORT_BY.name;
          delete state.category.search_for;
          break;
        case 3:
          state.category.sort_type = SORT_TYPE.desc;
          state.category.sort_by = SORT_BY.updatedAt;
          delete state.category.search_for;
          break;
        case 4:
          state.category.sort_type = SORT_TYPE.asc;
          state.category.sort_by = SORT_BY.updatedAt;
          delete state.category.search_for;
          break;
        case 5:
          state.category.search_for = SEARCH_FOR.relevance;
          delete state.category.sort_type;
          delete state.category.sort_by;
          break;
        default:
          delete state.category.sort_type;
          delete state.category.sort_by;
          delete state.category.search_for;
          break;
      }
      delete state.category.page;
    },
    updateFilterCategoriesArr: (
      state: Draft<FilterState>,
      {
        payload: { id, checked },
      }: PayloadAction<{ id: string; checked: boolean }>
    ): void => {
      const list = state.category.categories_values;

      const resetSort = () => {
        state.category.search_for = SEARCH_FOR.relevance;
        delete state.category.sort_type;
        delete state.category.sort_by;
      };

      if (!list || list.length === 0) {
        state.category.categories_values = [id];
        resetSort();
      } else {
        const newList = checked
          ? list.filter((el) => el !== id)
          : [...list, id];
        state.category.categories_values = newList;
        if (newList.length > 0) {
          resetSort();
        } else {
          delete state.category.search_for;
        }
      }
      delete state.category.page;
    },
    resetFilters: (state): void => {
      state.category = initialState.category;
    },
  },
});

export const {
  setSearchFilter,
  setPageNumFilter,
  updateFilterCategoriesArr,
  updateSortFilter,
  resetFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
