const styles = {
  MuiChip: {
    styleOverrides: {
      root: {
        color: "var(--text-title-1)",
        backgroundColor: "var(--background-secondary)",
        fontSize: "16px",
        letterSpacing: "0.5px",
        "&.active, &.checked:hover": {
          backgroundColor: "var(--chip-active)",
        },
        "&.Mui-focusVisible": {
          border: "1px solid var(--primary)",
        },
        ".MuiChip-deleteIcon": {
          fontSize: "18px",
          color: "var(--gray-5)",
        },
      },
    },
  },
};

export default styles;
