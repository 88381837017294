const styles = {
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "0 24px 24px 24px",
        ".MuiDialogContentText": {
          color: "var(--gray-5)",
          fontSize: "var(--text-body-1)",
          lineHeight: "142.857%",
          letterSpacing: "0.25px",
        },
      },
    },
  },
};

export default styles;
