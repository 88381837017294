import { FilterPanel } from "@components/FilterPanel";
import { ResultsPanel } from "@components/ResultsPanel";
import React from "react";
import styles from "./styles.module.css";

export const Main = () => {
  return (
    <div className={styles.main}>
      <FilterPanel />
      <ResultsPanel />
    </div>
  );
};
