import React, {useEffect, createRef, useState, useRef} from "react";
import styles from "./styles.module.css"
import AvatarEditor from "react-avatar-editor";
import { Button, Slider, Avatar } from "@mui/material";

type ProfileImageProps = {
  url: string;
  alt: string
  isProfileEditing: boolean;
  onPhotoUpdate: (img: string) => void
  onPhotoRemove: () => void,
  onCancelUpdate: () => void
};

interface ImageProperties {
  originalImage: string | File;
  croppedImage: string | undefined;
  position: { x: number; y: number };
  scale: number;
  rotate: number;
  isImageEditing: boolean;
}

export const ProfileImage = ({ url, alt, isProfileEditing, onPhotoUpdate, onPhotoRemove, onCancelUpdate }: ProfileImageProps) => {
  const editorRef: React.RefObject<AvatarEditor> = createRef();
  const fileInputRef = useRef<HTMLInputElement| null>(null);
  const [imageProperties, setImageProperties] = useState<ImageProperties>({
    isImageEditing: false,
    originalImage: url,
    croppedImage: url,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0
  });

  const {
    isImageEditing,
    originalImage,
    position,
    scale,
    rotate
  } = imageProperties;

  const handleAdd = (event: React.ChangeEvent<any>) =>{
    setImageProperties((prevState) => ({
      ...prevState,
      isImageEditing: true,
    }));
    const file = event.target.files[0];
    if (file) {
      setImageProperties((prevState) => ({
        ...prevState,
        originalImage: file,
      }));
    }
  }

  const handleZoom = (event: any, newValue: number | number[]) => {
    const scale = +newValue;
    setImageProperties((prevState) => ({ ...prevState, scale }));
  }

  const updateProfileImage = () => {
    if (editorRef?.current) {
      const canvasScaled: HTMLCanvasElement = editorRef.current.getImageScaledToCanvas();
      onPhotoUpdate(canvasScaled.toDataURL());
    }
  }

  useEffect(()=>{
    updateProfileImage();
  }, [position, scale]);

  const handlePositionChange = (position: ImageProperties["position"]) => {
    setImageProperties((prevState) => ({ ...prevState, position }));
  }
  
  const handleUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onPhotoRemove();
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setImageProperties((prevState) => ({
      ...prevState,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      isImageEditing: false,
    }));
    onCancelUpdate();
  }

  return (
    <div className={styles.profileImage}>
      {
        isImageEditing 
        && 
        <>
          <AvatarEditor 
            ref={editorRef}
            color={[0, 0, 0, 0.3]}
            border={1}
            style={{ width: "100%", height: "100%" }}
            borderRadius={150}
            scale={scale}
            image={originalImage}
            rotate={rotate}
            position={position}
            onPositionChange={handlePositionChange}
            onImageReady={updateProfileImage}
          />
          <div className={styles.editorActions}>
            <Slider
              className={styles.slider}
              aria-label="Image zoom slider"
              valueLabelDisplay="auto"
              min={0.5}
              max={2}
              step={0.1}
              value={scale}
              defaultValue={scale}
              onChange={handleZoom}
            />
            <div className={styles.buttonsWrapper}>
            <Button
              type="reset"
              aria-label="Image editing cancel"
              color="primary"
              onClick={handleCancel}>
              Cancel upload
            </Button>
            </div>
          </div>
        </>
      }
      { !isImageEditing && <Avatar className={styles.avatar} src={url} alt={alt}/> }
      {
        (isProfileEditing && !isImageEditing)
        && 
        <>
          <input
            ref={fileInputRef}
            style={{display: 'none'}}
            className="file-input-hidden"
            type="file"
            onChange={handleAdd}
            accept="image/*"
          />
          <Button onClick={handleUpload} className={styles.uploadButton} aria-label="Upload profile image">
          Upload new photo
          </Button>
          <Button color="secondary" onClick={handleRemove} className={styles.removeButton} aria-label="Remove profile image">
          Remove photo
          </Button>
        </>
      }
    </div>
  );
};
