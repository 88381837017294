import { Tooltip } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";

type Props = {
  text: string;
};

export function TextOverflow(props: Props) {
  const { text } = props;
  const [isOverflowed, setIsOverflow] = React.useState(false);
  const textElementRef = React.useRef<HTMLSpanElement>(null);

  const resizeHandler = () => {
    if (textElementRef.current) {
      const isLonger =
        textElementRef.current.scrollWidth > textElementRef.current.offsetWidth;
      if (isLonger) setIsOverflow(true);
    }
  };

  React.useEffect(() => {
    resizeHandler();
  }, []);

  return (
    <span className={styles.textEllipsis}>
      <Tooltip
        arrow
        placement="top"
        disableHoverListener={!isOverflowed}
        title={text}
      >
        <span ref={textElementRef}>{text}</span>
      </Tooltip>
    </span>
  );
}
