import {Filter} from "@app-redux/slices/filtersSlice";
import {ProfileListRes} from "@app-redux/slices/profileListSlice";
import {ProfileRes} from "@app-redux/slices/profileSlice";
import {DEFAULT_PAGE_LIST_PORTION, SEARCH_FOR, SORT_BY, SORT_TYPE,} from "@constants/index";
import {api} from "./axiosInstance";

const prepareQueryParameters = (filters: GetProfileListParams) => {
  const {roles_ids = [], reps_ids = [], markets_ids = [], locations_ids = []} = filters;
  const isSortByRelevanceDefault =
    !filters.search_for &&
    !filters.sort_by &&
    !filters.sort_type &&
    (roles_ids.length ||
      reps_ids.length ||
      markets_ids.length ||
      locations_ids.length ||
      filters.search_term?.length);

  const isSortByNameDefault =
    !filters.search_for &&
    !filters.sort_by &&
    !filters.sort_type &&
    (!roles_ids.length ||
      !reps_ids.length ||
      !markets_ids.length ||
      !locations_ids.length ||
      !filters.search_term?.length);

  const mappedSearchFilters = {
    ...(roles_ids.length ? {roles_ids: roles_ids?.join(",")} : {}),
    ...(reps_ids.length ? {reps_ids: reps_ids?.join(",")} : {}),
    ...(markets_ids.length ? {markets_ids: markets_ids?.join(",")} : {}),
    ...(locations_ids.length
      ? {locations_ids: locations_ids?.join(",")}
      : {}),
    page_size: DEFAULT_PAGE_LIST_PORTION,
    ...(isSortByRelevanceDefault ? {search_for: SEARCH_FOR.relevance} : {}),
    ...(isSortByNameDefault
      ? {
        sort_type: filters.sort_type || SORT_TYPE.asc,
        sort_by: filters.sort_by || SORT_BY.name,
      }
      : {}),
  };

  return {
    ...filters,
    ...(filters.search_for === SEARCH_FOR.pageLink
      ? {}
      : mappedSearchFilters),
  };
}

export type GetProfileListParams = Omit<Filter, "categories_values"> & {
  roles_ids?: ProfileRes["roles_ids"];
  reps_ids?: ProfileRes["reps_ids"];
  markets_ids?: ProfileRes["markets_ids"];
  locations_ids?: ProfileRes["locations_ids"];
};

export const apiGetProfileList = async (
  filters: GetProfileListParams
): Promise<ProfileListRes> => {
  try {

    const params = prepareQueryParameters(filters);
    const response = await api.get("/api/page/profile", {params});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiExportProfileList = async (
  filters: GetProfileListParams
): Promise<void> => {
  try {

    const params = prepareQueryParameters(filters);
    const response = await api.get("/api/page/profile/export", {params, responseType: "blob"});
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Profile_${new Date().toLocaleString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
    return response.data;
  } catch (error) {
    throw error;
  }
};
