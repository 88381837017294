import {
  apiGetUsedData,
  apiUpdateFavorite,
} from "@api/userData";
import {
  UserDataRes,
  setUserData,
  updateFavorites,
} from "@app-redux/slices/userDataSlice";
import type { UserFavorites } from "@app-redux/slices/userDataSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCategory } from "./categoryThunk";
import { USER_ROLE } from "@constants/index";

export const getUserData = createAsyncThunk<UserDataRes>(
  "userData/get",
  async (_, thunkAPI) => {
    try {
      const res = await apiGetUsedData();
      const userRoles = res.roles || [USER_ROLE.reader];
      const response = { 
        ...res,
        roles: userRoles,
        isAdmin: userRoles.includes(USER_ROLE.admin),
      };
      thunkAPI.dispatch(setUserData(response));
      thunkAPI.dispatch(
        getCategory(response?.preferences || {})
      );
      return response;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);

export const updateFavoriteThunk = createAsyncThunk<
  UserDataRes,
  UserFavorites
>("userData/favorite/update", async (params, thunkAPI) => {
  try {
    const res = await apiUpdateFavorite(params);
    thunkAPI.dispatch(updateFavorites(params));
    return res;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
  }
});
