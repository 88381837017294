const styles = {
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: "12px",
        letterSpacing: "0.25px",
        ".MuiAlert-icon": {
          display: "none",
        },
        "&.MuiAlert-standardError" :{
          color: "var(--error-color)",
          border: "1px solid var(--error-color)",
        }
      },
    },
  },
};

export default styles;
