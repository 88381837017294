import { isUserDataSelector, userNameSelector } from "@app-redux/selectors";
import { UserMenu } from "@components/UserMenu";
import { ROUTER_PATHS } from "@constants/index";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

export const Header = () => {
  const isUserData = useSelector(isUserDataSelector);
  const name = useSelector(userNameSelector) ?? "";

  return (
    <header className={styles.header}>
      <Link
        to={ROUTER_PATHS.main}
        aria-label="Go home"
        className={name ? "" : styles.pointerNone}
      >
        <img
          loading="lazy"
          src={`../${process.env.PUBLIC_URL}assets/Logo_CL.svg`}
          alt="Logo"
        />
      </Link>
      {isUserData && <UserMenu name={name} />}
    </header>
  );
};
