import { authService } from "@/services/auth";
import { resetCategory } from "@app-redux/slices/categorySlice";
import { resetFilters } from "@app-redux/slices/filtersSlice";
import { resetProfileList } from "@app-redux/slices/profileListSlice";
import { resetProfile } from "@app-redux/slices/profileSlice";
import { resetUserData } from "@app-redux/slices/userDataSlice";
import { useAppDispatch } from "@app-redux/store";
import { ROUTER_PATHS } from "@constants/index";
import LogoutIcon from "@mui/icons-material/Logout";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {
  Avatar,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { stringAvatar } from "@utils/index";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

export const UserMenu = ({ name }: { name: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSignOut = () => {
    authService.removeToken();
    dispatch(resetUserData());
    dispatch(resetProfileList());
    dispatch(resetProfile());
    dispatch(resetFilters());
    dispatch(resetCategory());
    navigate(ROUTER_PATHS.login);
  };

  const openMenu = Boolean(anchorEl);
  return (
    <>
      <IconButton
        sx={{ p: 0 }}
        color="inherit"
        aria-label="Menu"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
        aria-controls={openMenu ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
      >
        <Avatar
          className={styles.userLogo}
          sx={{ width: 32, height: 32 }}
          {...stringAvatar(name)}
        />
      </IconButton>
      <Menu
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuItem divider className={styles.pointerNone}>
          <ListItemText className="list-header">{name}</ListItemText>
        </MenuItem>
        <li>
          <NavLink to={ROUTER_PATHS.categorySettings}>
            {({ isActive }) => (
              <MenuItem
                className={styles.linkMenuItem}
                divider
                component="div"
                selected={isActive}
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <PlaylistAddCheckIcon color="inherit" />
                </ListItemIcon>
                <ListItemText>Category Settings</ListItemText>
              </MenuItem>
            )}
          </NavLink>
        </li>
        <MenuItem
          divider
          onClick={() => {
            handleSignOut();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LogoutIcon color="inherit" />
          </ListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
