import React from "react";
import styles from "./styles.module.css";

export const COUNTRY_MAP = {
  Afghanistan: "afghanistan.svg",
  Albania: "albania.svg",
  Algeria: "algeria.svg",
  Andorra: "andorra.svg",
  Angola: "angola.svg",
  "Antigua and Barbuda": "antigua-and-barbuda.svg",
  Argentina: "argentina.svg",
  Armenia: "armenia.svg",
  Aruba: "aruba.svg",
  Australia: "australia.svg",
  Austria: "austria.svg",
  Azerbaijan: "azerbaijan.svg",
  Bahamas: "bahamas.svg",
  Bahrain: "bahrain.svg",
  Bangladesh: "bangladesh.svg",
  Barbados: "barbados.svg",
  Belarus: "belarus.svg",
  Belgium: "belgium.svg",
  Belize: "belize.svg",
  Benin: "benin.svg",
  Bhutan: "bhutan.svg",
  Bolivia: "bolivia.svg",
  "Bosnia and Herzegovina": "bosnia-and-herzegovina.svg",
  Botswana: "botswana.svg",
  "Bouvet Island": "bouvet-island.svg",
  Brazil: "brazil.svg",
  "Brunei Darussalam": "brunei-darussalam.svg",
  Bulgaria: "bulgaria.svg",
  "Burkina Faso": "burkina-faso.svg",
  Burundi: "burundi.svg",
  Cambodia: "cambodia.svg",
  Cameroon: "cameroon.svg",
  Canada: "canada.svg",
  "Cape Verde": "cape-verde.svg",
  "Central African Republic": "central-african-republic.svg",
  Chad: "chad.svg",
  Chile: "chile.svg",
  China: "china.svg",
  Colombia: "colombia.svg",
  Comoros: "comoros.svg",
  Congo: "congo.svg",
  "Costa Rica": "costa-rica.svg",
  Croatia: "croatia.svg",
  Cuba: "cuba.svg",
  "Cura Ao": "cura-ao.svg",
  Cyprus: "cyprus.svg",
  "Czech Republic": "czech-republic.svg",
  Denmark: "denmark.svg",
  Djibouti: "djibouti.svg",
  Dominica: "dominica.svg",
  "Dominican Republic": "dominican-republic.svg",
  "DR Congo": "dr-congo.svg",
  Ecuador: "ecuador.svg",
  Egypt: "egypt.svg",
  "El Salvador": "el-salvador.svg",
  "Equatorial Guinea": "equatorial-guinea.svg",
  Eritrea: "eritrea.svg",
  Estonia: "estonia.svg",
  Eswatini: "eswatini.svg",
  Ethiopia: "ethiopia.svg",
  Europe: "europe.svg",
  Fiji: "fiji.svg",
  Finland: "finland.svg",
  France: "france.svg",
  Gabon: "gabon.svg",
  Gambia: "gambia.svg",
  Georgia: "georgia.svg",
  Germany: "germany.svg",
  Ghana: "ghana.svg",
  Greece: "greece.svg",
  Grenada: "grenada.svg",
  Guatemala: "guatemala.svg",
  "Guinea Bissau": "guinea-bissau.svg",
  Guinea: "guinea.svg",
  Guyana: "guyana.svg",
  Haiti: "haiti.svg",
  "Holy See": "holy-see.svg",
  Honduras: "honduras.svg",
  "Hong Kong": "hong-kong.svg",
  Hungary: "hungary.svg",
  Iceland: "iceland.svg",
  India: "india.svg",
  Indonesia: "indonesia.svg",
  Iran: "iran.svg",
  Iraq: "iraq.svg",
  Ireland: "ireland.svg",
  Israel: "israel.svg",
  Italy: "italy.svg",
  "Ivory Coast": "ivory-coast.svg",
  Jamaica: "jamaica.svg",
  Japan: "japan.svg",
  Jordan: "jordan.svg",
  Kazakhstan: "kazakhstan.svg",
  Kenya: "kenya.svg",
  Kiribati: "kiribati.svg",
  Korena: "Korena.svg",
  Kuwait: "kuwait.svg",
  Kyrgyzstan: "kyrgyzstan.svg",
  Laos: "laos.svg",
  Latvia: "latvia.svg",
  Lebanon: "lebanon.svg",
  Lesotho: "lesotho.svg",
  Liberia: "liberia.svg",
  Libya: "libya.svg",
  Liechtenstein: "liechtenstein.svg",
  Lithuania: "lithuania.svg",
  Luxembourg: "luxembourg.svg",
  Madagascar: "madagascar.svg",
  Malawi: "malawi.svg",
  Malaysia: "malaysia.svg",
  Maldives: "maldives.svg",
  Mali: "mali.svg",
  Malta: "malta.svg",
  "Marshall Islands": "marshall-islands.svg",
  Mauritania: "mauritania.svg",
  Mauritius: "mauritius.svg",
  Mexico: "mexico.svg",
  Micronesia: "micronesia.svg",
  Moldova: "moldova.svg",
  Monaco: "monaco.svg",
  Mongolia: "mongolia.svg",
  Montenegro: "montenegro.svg",
  Morocco: "morocco.svg",
  Mozambique: "mozambique.svg",
  Myanmar: "myanmar.svg",
  Namibia: "namibia.svg",
  Nauru: "nauru.svg",
  Nepal: "nepal.svg",
  Netherlands: "netherlands.svg",
  "New Zealand": "new-zealand.svg",
  Nicaragua: "nicaragua.svg",
  Niger: "niger.svg",
  Nigeria: "nigeria.svg",
  "North Korea": "north-korea.svg",
  "North Macedonia": "north-macedonia.svg",
  Norway: "norway.svg",
  Oman: "oman.svg",
  Pakistan: "pakistan.svg",
  Palau: "palau.svg",
  Palestine: "palestine.svg",
  Panama: "panama.svg",
  "Papua New Guinea": "papua-new-guinea.svg",
  Paraguay: "paraguay.svg",
  Peru: "peru.svg",
  Philippines: "philippines.svg",
  Poland: "poland.svg",
  Portugal: "portugal.svg",
  Qatar: "qatar.svg",
  Romania: "romania.svg",
  "Russian Federation": "russian-federation.svg",
  Rwanda: "rwanda.svg",
  "Saint Lucia": "saint-lucia.svg",
  "Saint Vicent and The Grenadines": "saint-vicent-and-the-grenadines.svg",
  Samoa: "samoa.svg",
  "San Marino": "san-marino.svg",
  "Sao Tome and Principe": "sao-tome-and-principe.svg",
  "Saudi Arabia": "saudi-arabia.svg",
  Senegal: "senegal.svg",
  Serbia: "serbia.svg",
  Seychelles: "seychelles.svg",
  "Sierra Leone": "sierra-leone.svg",
  Singapore: "singapore.svg",
  "Sint Maarten": "sint-maarten.svg",
  Slovakia: "slovakia.svg",
  Slovenia: "slovenia.svg",
  "Solomon Islands": "solomon-islands.svg",
  Somalia: "somalia.svg",
  "South Africa": "south-africa.svg",
  "South Sudan": "south-sudan.svg",
  Spain: "spain.svg",
  "Sri Lanka": "sri-lanka.svg",
  Sudan: "sudan.svg",
  Suriname: "suriname.svg",
  Sweden: "sweden.svg",
  Switzerland: "switzerland.svg",
  "Syrian Arab Republic": "syrian-arab-republic.svg",
  Tajikistan: "tajikistan.svg",
  Tanzania: "tanzania.svg",
  Thailand: "thailand.svg",
  "Timor Leste": "timor-leste.svg",
  Togo: "togo.svg",
  Tonga: "tonga.svg",
  "Trinidad and Tobago": "trinidad-and-tobago.svg",
  Tunisia: "tunisia.svg",
  Turkey: "turkey.svg",
  Turkmenistan: "turkmenistan.svg",
  Tuvalu: "tuvalu.svg",
  Uganda: "uganda.svg",
  Ukraine: "ukraine.svg",
  "United Arab Emirates": "united-arab-emirates.svg",
  "United Kingdom": "united-kingdom.svg",
  "United States": "united-states-of-america.svg",
  Uruguay: "uruguay.svg",
  Uzbekistan: "uzbekistan.svg",
  Vanuaty: "vanuaty.svg",
  Venezuela: "venezuela.svg",
  Vietnam: "viet-nam.svg",
  "Virgin Islands": "virgin-islands.svg",
  Yemen: "yemen.svg",
  Zambia: "zambia.svg",
  Zimbabwe: "zimbabwe.svg",
};

export type CountryFlagProp = { country: string };

export const CountryFlag = ({ country }: CountryFlagProp) => {
  const getImage = (country: string) => {
    return COUNTRY_MAP[country]
      ? `../${process.env.PUBLIC_URL}assets/flags/svg/${COUNTRY_MAP[country]}`
      : `../${process.env.PUBLIC_URL}assets/flags/default-country-flag.png`;
  };
  return (
    <img
      loading="lazy"
      className={styles.image}
      src={getImage(country)}
      alt={`${country} flag`}
    />
  );
};
