const styles = {
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "0 24px 24px 24px",
        "& button": {
          lineHeight: "142.857%",
          padding: "10px 12px",
        },
      },
    },
  },
};

export default styles;
