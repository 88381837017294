const styles = {
  MuiTextField: {
    styleOverrides: {
      root: {
        ".MuiInputBase-root": {
          borderRadius: "12px",
        },
        input: {
          padding: "8px 16px",
          height: "1.5em",
          letterSpacing: "0.5px",
          color: "var(--text-title-1)",
        },
        ".MuiFormHelperText-root": {
          display: "inline-flex",
          alignItems: "center",
          position: "absolute",
          top: "8px",
          right: 0,
          fontSize: "var(--text-body-1)",
          letterSpacing: "0.25px",
          margin: "0 16px",
          lineHeight: "1",
          backgroundColor: "var(--background-main)",
        },
        ".MuiFormHelperText-root svg ": {
          marginRight: "8px",
        },
      },
    },
  },
};

export default styles;
