import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Select, SelectProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const MAX_ITEMS_COUNT = 7;

const valueMapper = (value: string | string[]) => {
  return typeof value === "string"
    ? value
      ? (value as string).split(",")
      : []
    : value;
};

interface CustomSelectProps {
  error?: boolean;
  options: { value: string; title: string }[];
  required?: boolean;
  value?: string[];
  showItemsCount: number;
}

export const MultiSelect = forwardRef(function SelectRef(
  props: CustomSelectProps & SelectProps,
  ref
) {
  const {
    className,
    error,
    options,
    required,
    value: inputValue = [],
    label,
    showItemsCount,
    ...rest
  } = props;

  const height =
    showItemsCount <= MAX_ITEMS_COUNT ? showItemsCount : MAX_ITEMS_COUNT;

  return (
    <FormControl
      className={`${className} ${styles.formControl}`}
      error={error}
      required={required}
    >
      {!inputValue.length && <label className={styles.label}>{label}</label>}
      <Select
        {...rest}
        ref={ref}
        value={valueMapper(inputValue)}
        MenuProps={{
          disablePortal: true,
          PaperProps: {
            style: {
              height: `calc(56px *${height})`,
            },
          },
        }}
        multiple
      >
        {options.map(({ value, title }) => {
          const icon = inputValue?.includes(value) ? (
            <CheckBoxIcon color="primary" className={styles.checkbox} />
          ) : (
            <CheckBoxOutlineBlankIcon
              className={[styles.checkbox, styles.inactive].join(" ")}
            />
          );

          return (
            <MenuItem key={value} value={value} className="divider-inset">
              {icon}
              {title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});
