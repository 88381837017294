import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ProfileRes } from "@app-redux/slices/profileSlice";
import {TOGGLE_LINK_COMMAND} from '@lexical/link';
import { apiGetProfileList } from "@api/profileList";
import { SEARCH_FOR, ROUTER_PATHS } from "@constants/index";
import { ErrorMessage } from "@components/ErrorMessage";
import type { LinkProps } from '@components/Editor/components/ExternalLinkEditorComponent';

const LINK_PREFIX =  `/${ROUTER_PATHS.profile}/`;
const getLinkId = (linkUrl: string) => linkUrl.split(LINK_PREFIX)[1];

export function InternalLinkEditorComponent({
  editor,
  linkUrl,
  linkTitle,
  lastSelection,
  setLinkUrl
} : LinkProps) {
  const [options, setOptions] = useState<ProfileRes[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState<ProfileRes>({id: "", name: ""});
  const searchProfile = async (value: string) => {
    setLoading(true);
    try {
      const params = {
        search_term: value,
        search_for: SEARCH_FOR.pageLink,
      };
      const data = await apiGetProfileList({ ...params });
      setOptions(data.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setValue({id: getLinkId(linkUrl), name: linkTitle || ""});
  }, [linkUrl, linkTitle])

  const handleAutocompleteChange = (event: React.MouseEvent, value: ProfileRes) => {
    event.preventDefault();
    if (lastSelection !== null && (value && value.id)) {
      const linkAttrs = {
        url: `${LINK_PREFIX}${value.id}`,
        title: `${value.name}`,
      };
      setLinkUrl(`${LINK_PREFIX}${value.id}`);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkAttrs);
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      disableClearable
      options={options}
      loading={loading}
      value={value}
      sx={{ width: 300 }}
      onChange={(event: React.MouseEvent, value) => {
        handleAutocompleteChange(event, value);
        setValue(value);
        setLoading(false);
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue && newInputValue !== linkTitle) {
          searchProfile(newInputValue);
        }
        setValue({id: "", name: newInputValue});
      }}
      renderInput={(params) => 
        <TextField
          {...params}
          autoComplete="off"
          variant="outlined"
          placeholder="Enter the name"
          aria-label='Internal link input'
          error={error}
          helperText={<ErrorMessage message={error ? "Search error" : ""} />}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? 'Loading...' : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />}
      getOptionLabel={(option) => option.name ?? ""}
    />
  );
}