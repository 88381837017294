const styles = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: "var(--gray-1)",
        color: "var(--text-title-2)",
        letterSpacing: "-0.32px",
        textTransform: "uppercase",
      },
    },
  },
};

export default styles;
