const styles = {
  MuiListItemSecondaryAction: {
    styleOverrides: {
      root: {
        color: "var(--gray-5)",
        fontSize: "var(--text-body-1)",
      },
    },
  },
};

export default styles;
