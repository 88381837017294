import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "../thunk/profileThunk";

export type ProfileRes = {
  id: string;
  content?: Record<string, unknown>;
  name: string;
  roles_ids?: string[];
  reps_ids?: string[];
  markets_ids?: string[];
  locations_ids?: string[];
  vendor?: string;
  account_representative?: string;
  image_url?: string;
  created_at?: string;
  updated_at?: string;
};

export type ProfileState = {
  data: Record<ProfileRes["id"], ProfileRes | undefined>;
  isLoading: boolean;
  error: string;
};

export const initialProfileState: ProfileState = {
  data: {},
  isLoading: false,
  error: "",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<ProfileRes>) => {
      state.data[payload.id] = payload;
    },
    deleteProfileFromData: (state, { payload }: PayloadAction<string>) => {
      if (state.data[payload]) state.data[payload] = undefined;
    },
    updateProfile: (
      state,
      { payload }: PayloadAction<{ params: Partial<ProfileRes>; id: string }>
    ) => {
      if (state.data[payload.id]) {
        state.data[payload.id] = {
          ...state.data[payload.id],
          ...(payload.params as ProfileRes),
        };
      }
    },
    resetProfileError: (state) => {
      state.error = "";
    },
    resetProfile: (state) => {
      state.data = initialProfileState.data;
      state.error = initialProfileState.error;
      state.isLoading = initialProfileState.isLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  resetProfile,
  setProfile,
  updateProfile,
  deleteProfileFromData,
  resetProfileError,
} = profileSlice.actions;

export default profileSlice.reducer;
