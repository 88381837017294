import { Chip, Stack } from "@mui/material";
import React from "react";

export type ChipGroupProps = {
  options: { title: string; value: string }[];
  onChange?: (id: string) => void;
  value?: string[];
};

export const ChipGroup = ({ onChange, options, value }: ChipGroupProps) => {
  return (
    <Stack direction="row" spacing="4px" flexWrap="wrap" sx={{ ml: "4px" }}>
      {options.map(({ title, value: id }) => {
        const checked = value ? value.includes(id) : false;
        return (
          <Chip
            key={id}
            label={title}
            onClick={onChange ? () => onChange(id) : undefined}
            className={checked ? "active" : ""}
          />
        );
      })}
    </Stack>
  );
};
