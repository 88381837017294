import { ResAuth } from "@api/auth";

type AuthService = {
  getToken: (type: keyof ResAuth) => string | undefined;
  setToken: (token: ResAuth) => void;
  removeToken: () => void;
  isLoading: boolean;
  awaitToken: (type: keyof ResAuth) => Promise<string | undefined>;
};

export const authService: AuthService = {
  isLoading: false,
  awaitToken: async (type) => {
    if (authService.isLoading) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!authService.isLoading) {
            clearInterval(interval);
            resolve(authService.getToken(type));
          }
        }, 100);
      });
    } else {
      return authService.getToken(type);
    }
  },
  getToken: (type) => {
    let currentToken = undefined;
    if (!currentToken) {
      const restoredToken = localStorage.getItem("tokens");
      const tokens: ResAuth | null = restoredToken
        ? JSON.parse(restoredToken)
        : null;
      currentToken = tokens ? tokens[type] : undefined;
    }
    return currentToken;
  },
  setToken: ({ access_token, refresh_token }) => {
    const token = { access_token, refresh_token };
    localStorage.setItem("tokens", JSON.stringify(token));
  },
  removeToken: () => {
    localStorage.removeItem("tokens");
  },
};
