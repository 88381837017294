import React from "react";
import { v4 } from "uuid";
import EditorTheme from "@components/Editor/themes/EditorTheme";
import {
  InitialConfigType,
  LexicalComposer
} from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HeadingNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LexicalEditor } from "lexical/LexicalEditor";
import { $getRoot, EditorState } from "lexical";

import ToolbarPlugin from "@components/Editor/plugins/ToolbarPlugin";
import AutoLinkPlugin from "@components/Editor/plugins/AutoLinkPlugin";
import ImagesPlugin from "@components/Editor/plugins/ImagesPlugin";

import { ImageNode } from "@components/Editor/nodes/ImageNode";


function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

export interface EditorStateChangeProps {
  editor_state: EditorState | string;
  raw_page_content: string;
  page_content: string;
}

export interface EditorProps {
  onStateChange?: (obj: EditorStateChangeProps) => void;
  editable?: boolean;
  editorState?: EditorState | string;
  editorKey: string ;
}

export default function Editor({
  onStateChange,
  editable = true,
  editorKey,
  editorState,
}: EditorProps) {
  const editorStateRef = React.useRef<EditorState | null>(null);
  const editorRef = React.useRef<LexicalEditor | null>(null);
  const editorConfig: InitialConfigType = React.useMemo(
    () => ({
      // The editor theme
      theme: EditorTheme,
      // Handling of errors during update
      onError(error: Error, editor: LexicalEditor) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HorizontalRuleNode,
        HeadingNode,
        ListNode,
        ListItemNode,
        AutoLinkNode,
        LinkNode,
        ImageNode,
      ],
      namespace: `Editor ${v4()}`,
      editable,
      editorState,
    }),
    [editable, editorState]
  );

  const onEditorStateChange = () => {
    if (editorStateRef.current) {
      const editorState = editorRef?.current?.getEditorState();
      const jsonPageContent = editorState?.toJSON();
      const rawPageContent = editorState?.read(() => $getRoot().getTextContent())
      const data = {
        editor_state: editorStateRef.current,
        raw_page_content: JSON.stringify(rawPageContent) || '',
        page_content: JSON.stringify(jsonPageContent) || '',
      }
      onStateChange?.(data);
    }
  }

  return (
    <LexicalComposer initialConfig={editorConfig} key={editorKey}>
      <div className={editable ? "editor-container editing" : "editor-container"}>
        {editable && <ToolbarPlugin />}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {editable && (
            <>
              <HistoryPlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <HorizontalRulePlugin />
              <ImagesPlugin />
              <OnChangePlugin
                onChange={(editorState, editor) => {
                  editorStateRef.current = editorState;
                  editorRef.current = editor;
                  onEditorStateChange();
                }}
                ignoreSelectionChange
              />
            </>
          )}
        </div>
      </div>
    </LexicalComposer>
  );
}
