import { apiPostCategoryItem } from "@/api/category";
import { categorySelector, favoritesSelector } from "@app-redux/selectors";
import { CategoryRes } from "@app-redux/slices/categorySlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { getCategory } from "@app-redux/thunk/categoryThunk";
import { COUNTRY_MAP, CountryFlag } from "@components/CountryFlag";
import { CATEGORY_INDEX_MAP } from "@constants/index";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContentText,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";

type Props = {
  id?: string;
  name?: string;
  open: boolean;
  close: () => void;
  isLocation: boolean;
};

type Option = {
  title: string;
  value?: string;
  inputValue?: string;
};

type AutocompleteOption = string | Option;

const filter = createFilterOptions<AutocompleteOption>();

function getLocationOptions(
  items: { name: string }[]
): { title: string; value: string }[] {
  const excludedNames = new Set(items.map(({ name }) => name));
  const filteredNames = Object.keys(COUNTRY_MAP).filter(
    (name) => !excludedNames.has(name)
  );
  return filteredNames.map((name) => ({ title: name, value: name }));
}

const SelectOption = ({
  title,
  selectProps,
}: {
  title: string;
  selectProps: React.HTMLAttributes<HTMLLIElement>;
}) => (
  <MenuItem {...selectProps}>
    <CountryFlag country={title} />
    {title}
  </MenuItem>
);

const isCategoryNewName = (data: CategoryRes, id: string, newValue: string) => {
  return (
    data
      .filter((category) => category.id === id)[0]
      .items.filter((item) => item.name === newValue).length === 0
  );
};

export const EditCategoryDialog = ({
  isLocation,
  id = "",
  name = "",
  open,
  close,
}: Props) => {
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(favoritesSelector);
  const text = `Name ${name}`;
  const [value, setValue] = React.useState("");
  const [locationOptions, setLocationOptions] = React.useState<Option[]>([]);
  const { data = [] } = useAppSelector(categorySelector);
  const [location, setLocation] = React.useState<AutocompleteOption | null>(
    null
  );

  const newValue = isLocation
    ? typeof location === "string"
      ? location
      : location?.inputValue ?? location?.title
    : value;

  const onSubmit = async () => {
    if (isCategoryNewName(data, id, newValue as string)) {
      try {
        await apiPostCategoryItem({
          id,
          items: [{ name: newValue as string }],
        });
        await dispatch(getCategory(favorites));
      } catch (error) {
        console.log(error); // todo: show server error message
      }
    }
    onReset();
  };

  const onReset = () => {
    close();
    setValue("");
    setLocation(null);
    setLocationOptions([]);
  };

  useEffect(() => {
    if (open && isLocation)
      setLocationOptions(
        getLocationOptions(data[CATEGORY_INDEX_MAP.LOCATION]?.items ?? [])
      );
  }, [open, isLocation]);

  return (
    <Dialog
      open={open}
      onClose={onReset}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { overflow: "visible", width: "340px" } }}
    >
      <DialogTitle id="alert-dialog-title">Add New {name}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: "16px" }}>
          {isLocation
            ? "Enter the new country you need to add."
            : `Enter a name for the new ${name.toLowerCase()}`}
        </DialogContentText>
        {isLocation ? (
          <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            clearIcon={null}
            disablePortal
            componentsProps={{
              paper: {
                sx: {
                  borderRadius: "var(--border-radius-main)",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.08)",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                type="text"
                placeholder="Search"
              />
            )}
            options={locationOptions}
            value={location}
            onChange={(event, newValue, reason, details) => {
              setLocation(newValue);
            }}
            renderOption={(props, option) => {
              const title = typeof option === "string" ? option : option.title;
              return (
                <SelectOption key={title} title={title} selectProps={props} />
              );
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") return option;
              if (option.inputValue) return option.inputValue;
              return option.title;
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (
                typeof params === "object" &&
                params.inputValue &&
                params.inputValue !== ""
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered as Option[];
            }}
          />
        ) : (
          <TextField
            autoFocus
            value={value}
            onChange={(e) => setValue(e.target.value)}
            aria-label={text}
            autoComplete="off"
            fullWidth
            placeholder={text}
            required
            type="text"
            inputProps={{
              maxLength: 300,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="reset"
          aria-label="reset"
          variant="text"
          onClick={onReset}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          aria-label="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!newValue}
        >
          Add {name}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
