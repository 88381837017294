import { api } from "./axiosInstance";

export const REFRESH_PATH = "/api/authenticate/refresh";
export const AUTH_PATH = "/api/authenticate";

export type LoginParams = {
  userName: string;
  password: string;
};

export type ResAuth = {
  access_token: string;
  refresh_token: string;
};

export const apiGetAuth = async (params: LoginParams): Promise<ResAuth> => {
  try {
    const response = await api.post(AUTH_PATH, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiRefreshAuth = async (
  refresh_token: string
): Promise<ResAuth> => {
  try {
    const response = await api.post(REFRESH_PATH, refresh_token);
    return response.data;
  } catch (error) {
    throw error;
  }
};


