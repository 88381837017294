import { UserDataRes } from "@app-redux/slices/userDataSlice";
import type { UserFavorites } from "@app-redux/slices/userDataSlice";
import { api } from "./axiosInstance";

export const apiGetUsedData = async (): Promise<UserDataRes> => {
  try {
    const response = await api.get(`/api/user`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiUpdateFavorite = async ({
  favorite_locations, favorite_roles, favorite_reps, favorite_markets
}: UserFavorites): Promise<UserDataRes> => {
  const params = {
    favorite_locations,
    favorite_roles,
    favorite_reps,
    favorite_markets,
  };
  try {
    const response = await api.patch(`/api/user`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};
