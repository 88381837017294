import { apiDeleteCategoryItem, apiPatchCategoryItem } from "@/api/category";
import {
  categorySelector,
  favoritesSelector,
  filtersSelector,
  isAdminUser,
} from "@app-redux/selectors";
import {
  Category,
  CategoryItem,
  sortCategory,
} from "@app-redux/slices/categorySlice";
import { updateFilterCategoriesArr } from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { getCategory } from "@app-redux/thunk/categoryThunk";
import { updateFavoriteThunk } from "@app-redux/thunk/userDataThunk";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { CountryFlag } from "@components/CountryFlag";
import { EditCategoryDialog } from "@components/EditCategoryDialog";
import { Loader } from "@components/Loader";
import { CATEGORY_INDEX_MAP, FAVORITE_MAP } from "@constants/index";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./styles.module.css";

const Icon = ({
  icon,
  onClick,
  label,
}: {
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
}) => (
  <IconButton aria-label={label} onClick={onClick} sx={{ p: "0" }}>
    {icon}
  </IconButton>
);

const Item = ({
  id,
  name,
  count,
  isLocation,
  category,
}: CategoryItem & { isLocation: boolean, category: string }) => {
  const isUsed = Boolean(count);
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(favoritesSelector) || [];
  const filters = useAppSelector(filtersSelector);
  const [editMode, setEditMode] = React.useState(false);
  const [value, setValue] = React.useState(name);
  const isFavorite = Object.values(favorites).flat().includes(id);
  const isAdmin = useAppSelector(isAdminUser);
  const starIcon = isFavorite ? (
    <StarOutlinedIcon />
  ) : (
    <StarBorderOutlinedIcon />
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const save = async () => {
    try {
      await apiPatchCategoryItem({ id, value });
      await dispatch(getCategory(favorites));
    } catch (error: any) {
      setValue(name);
      console.log(error);
    }
    setEditMode(false);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      save();
    } else if (e.key === "Escape") {
      setValue(name);
      setEditMode(false);
    }
  };

  const onDelete = async () => {
    try {
      await apiDeleteCategoryItem(id);
      await dispatch(getCategory(favorites));
      const isFilterApplied = filters?.categories_values?.includes(id);
      if (isFilterApplied) {
        await dispatch(
          updateFilterCategoriesArr({
            id,
            checked: true,
          })
        );
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getFavoriteObj = () => {
    let obj = {};
    Object.values(FAVORITE_MAP).forEach(value => {
      if (favorites[value]) {
        obj[value] = favorites[value];
      } else {
        obj[value] = [];
      }
    });
    return obj;
  }

  const onFavorite = async () => {
    const obj = getFavoriteObj();
    obj[FAVORITE_MAP[category]] = isFavorite
      ? obj[FAVORITE_MAP[category]].filter((i:string) => i !== id)
      : [...obj[FAVORITE_MAP[category]] , id];

    dispatch(updateFavoriteThunk(obj));
  };

  const flag = isLocation ? <CountryFlag country={name} /> : null;

  return !editMode ? (
    <ListItem
      disableGutters
      secondaryAction={
        <Stack direction="row" gap="8px">
          {!isUsed && isAdmin && (
            <Icon
              label="delete"
              onClick={onDelete}
              icon={<DeleteOutlineOutlinedIcon />}
            />
          )}
          {!isLocation && isAdmin && (
            <Icon
              label="edit"
              onClick={() => {
                setEditMode(!editMode);
              }}
              icon={<EditOutlinedIcon />}
            />
          )}
          <Icon label="favorite" onClick={onFavorite} icon={starIcon} />
        </Stack>
      }
    >
      <ListItemText
        className={styles.textContent}
        primary={
          <>
            {flag}
            {name}
          </>
        }
        secondary={`(${count})`}
      ></ListItemText>
    </ListItem>
  ) : (
    <li>
      <TextField
        onBlur={() => save()}
        autoFocus
        fullWidth
        value={value || ""}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type="text"
        placeholder="Category name"
        aria-label="Category name"
        variant="outlined"
        size="small"
      />
    </li>
  );
};

const CategoryList = ({
  name,
  items = [],
  isLocation,
}: Category & { isLocation: boolean }) => {
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  return (
    <List
      className={styles.list}
      subheader={<ListSubheader disableGutters>{name}</ListSubheader>}
    >
      {sortedItems.map((data) => (
        <Item key={data.id} {...data} category={name} isLocation={isLocation} />
      ))}
    </List>
  );
};

export const CategorySettings = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(categorySelector);
  const [openCategoryIndex, setOpenCategoryIndex] = React.useState(-1);
  const favorites = useAppSelector(favoritesSelector);
  const isAdmin = useAppSelector(isAdminUser);

  useEffect(() => {
    if (favorites && data.length) {
      dispatch(sortCategory(favorites));
    }
  }, [favorites]);

  return (
    <>
      <Loader className="content" show={!data.length} />
      <Breadcrumbs width="720px" pageName="Category Settings" />
      <div className={styles.container}>
        <h1>Category Settings</h1>
        {data.map((item, i) => {
          const open = openCategoryIndex === i;
          const isLocation = i === CATEGORY_INDEX_MAP.LOCATION;
          return (
            <React.Fragment key={item.id}>
              <CategoryList {...item} isLocation={isLocation} />
              { isAdmin && <Button
                className={styles.add}
                arial-label="add"
                onClick={() => setOpenCategoryIndex(i)}
              >
                <AddIcon fontSize="small" />
                Add New {item.name}
              </Button> }
              {open && (
                <EditCategoryDialog
                  isLocation={isLocation}
                  id={item.id}
                  name={item.name}
                  open={open}
                  close={() => setOpenCategoryIndex(-1)}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
