import { categorySelector, filtersSelector } from "@app-redux/selectors";
import type { CategoryItem } from "@app-redux/slices/categorySlice";
import { updateFilterCategoriesArr } from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { CountryFlag } from "@components/CountryFlag";
import { TextOverflow } from "@components/TextOverflow";
import { CATEGORY_INDEX_MAP } from "@constants/index";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Checkbox, ListItemButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { memo, useCallback } from "react";
import styles from "./styles.module.css";

const ItemMemo = memo(
  ({
    id,
    name,
    checked,
    count,
    categoryName,
    isLocation,
  }: CategoryItem & {
    checked: boolean;
    categoryName: string;
    isLocation: boolean;
  }) => {
    const label = `Filter-${categoryName}-${name}`;
    const dispatch = useAppDispatch();

    const onChange = useCallback(() => {
      dispatch(updateFilterCategoriesArr({ id, checked }));
    }, [id, checked, dispatch]);

    return (
      <ListItem secondaryAction={count.toString()} disablePadding>
        <ListItemButton role={undefined} onClick={onChange}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              aria-label={label}
              inputProps={{ "aria-labelledby": label }}
            />
          </ListItemIcon>
          {isLocation && <CountryFlag country={name} />}
          <ListItemText id={label} primary={<TextOverflow text={name} />} />
        </ListItemButton>
      </ListItem>
    );
  }
);

const Item = ({
  item,
  categoryName,
  isLocation,
}: {
  item: CategoryItem;
  categoryName: string;
  isLocation: boolean;
}) => {
  const { categories_values = [] } = useAppSelector(filtersSelector);
  const checked = categories_values.includes(item.id);

  return (
    <ItemMemo
      {...item}
      checked={checked}
      categoryName={categoryName}
      isLocation={isLocation}
    />
  );
};

const FilterGroup = ({ index }: { index: number }) => {
  const { data } = useAppSelector(categorySelector);
  const { name, items } = data[index];
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="div" className={styles.list}>
      <ListItemButton
        onClick={handleClick}
        aria-label={`${name} collapse button`}
        className={styles.title}
      >
        <ListItemText primary={name} className={styles.titleText} />
        {open ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={styles.nestedListWrapper}
      >
        <List disablePadding className={styles.nestedList}>
          {items.map((value) => (
            <Item
              key={value.id}
              item={value}
              categoryName={name}
              isLocation={index === CATEGORY_INDEX_MAP.LOCATION}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export const FilterPanel = () => {
  const { data } = useAppSelector(categorySelector);
  const dataWithIndex = data.map((item, index) => ({ ...item, index }));
  const sortedData = data.length
    ? [
        dataWithIndex[CATEGORY_INDEX_MAP.REP],
        ...dataWithIndex.filter((_, i) => i !== CATEGORY_INDEX_MAP.REP),
      ]
    : dataWithIndex;
  return (
    <div className={styles.filtersPanel}>
      {sortedData.map(({ id, index }) => (
        <FilterGroup key={id} index={index} />
      ))}
    </div>
  );
};
