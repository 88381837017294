const styles = {
  MuiMenu: {
    styleOverrides: {
      root: {
        ".MuiPaper-root": {
          borderRadius: "12px",
          minWidth: "172px",
          filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08))",
        },
        ".MuiList-root": {
          padding: "0",
          color: 'var(--text-title-1)',
        },
        ".MuiMenuItem-root": {
          minHeight: "54px",
          borderColor: "var(--divider-main)",
          padding: "8px 16px",
        },
        "li.Mui-selected": {
          backgroundColor: "var(--background-secondary)",
        },
        "li.Mui-selected.Mui-focusVisible, li.Mui-selected:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
        ".list-header span": {
          fontWeight: 'var(--font-weight-medium)'
        }
      },
    },
  },
};

export default styles;
