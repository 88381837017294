import { createTheme, ThemeOptions } from "@mui/material/styles";
import MuiAlert from "./components/MuiAlert";
import MuiAutocomplete from "./components/MuiAutocomplete";
import MuiAvatar from "./components/MuiAvatar";
import MuiBackdrop from "./components/MuiBackdrop";
import MuiBreadcrumbs from "./components/MuiBreadcrumbs";
import MuiButton from "./components/MuiButton";
import MuiCheckbox from "./components/MuiCheckbox";
import MuiChip from "./components/MuiChip";
import MuiDialog from "./components/MuiDialog";
import MuiDialogActions from "./components/MuiDialogActions";
import MuiDialogContent from "./components/MuiDialogContent";
import MuiDialogTitle from "./components/MuiDialogTitle";
import MuiListItem from "./components/MuiListItemButton";
import MuiListItemIcon from "./components/MuiListItemIcon";
import MuiListItemSecondaryAction from "./components/MuiListItemSecondaryAction";
import MuiMenuItem from "./components/MuiMenuItem";
import MuiMenuList from "./components/MuiMenuList";
import MuiSelect from "./components/MuiSelect";
import MuiTextField from "./components/MuiTextField";

const theme: ThemeOptions = {
  palette: {
    primary: { main: "rgba(70, 128, 216, 1)" },
  },
};

export default createTheme(theme, {
  components: {
    ...MuiAlert,
    ...MuiAutocomplete,
    ...MuiAvatar,
    ...MuiBackdrop,
    ...MuiButton,
    ...MuiCheckbox,
    ...MuiChip,
    ...MuiDialog,
    ...MuiDialogActions,
    ...MuiDialogContent,
    ...MuiDialogTitle,
    ...MuiListItem,
    ...MuiListItemIcon,
    ...MuiListItemSecondaryAction,
    ...MuiMenuItem,
    ...MuiMenuList,
    ...MuiSelect,
    ...MuiTextField,
    ...MuiBreadcrumbs,
  },
});
