import { deleteProfileFromData } from "@app-redux/slices/profileSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiExportProfileList, apiGetProfileList, GetProfileListParams } from "@api/profileList";
import {
  deleteProfileFromList,
  ProfileListRes,
  resetSelectedProfile,
  setNextProfileList,
  setProfileList,
} from "../slices/profileListSlice";

export const getProfileList = createAsyncThunk<
  ProfileListRes,
  GetProfileListParams
>("profileList/get", async (filters, thunkAPI) => {
  try {
    const data = await apiGetProfileList(filters);
    if (!filters.page) {
      thunkAPI.dispatch(setProfileList(data));
    } else {
      thunkAPI.dispatch(setNextProfileList(data.data));
    }
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
  }
});

export const exportProfileList = createAsyncThunk<
  void,
  GetProfileListParams
>("profileList/export", async (filters) => {
  await apiExportProfileList(filters);
});

export const deleteProfile = createAsyncThunk<any, string>(
  "profile/delete",
  async (id, thunkAPI) => {
    try {
      // const data = await apiDeleteProfile();
      thunkAPI.dispatch(deleteProfileFromList(id));
      thunkAPI.dispatch(deleteProfileFromData(id));
      thunkAPI.dispatch(resetSelectedProfile());
      return id;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);
