import { categorySelector, isCategoryMapSelector } from "@app-redux/selectors";
import { useAppSelector } from "@app-redux/store";
import { ChipGroup } from "@components/ChipGroup";
import { FormParams } from "@pages/Profile";
import { getCategoryItem, getSortedCategories } from "@utils/index";
import React from "react";
import styles from "./styles.module.css";

export const ProfileInfo = ({ profile }: { profile: FormParams }) => {
  const { data: categories, map } = useAppSelector(categorySelector);
  const isCategoryMap = useAppSelector(isCategoryMapSelector);

  const getCategoryNames = (arr: string[]) =>
    arr.map((id) => getCategoryItem(id, categories, map)?.name);

  const {
    name,
    roles_ids = [],
    reps_ids = [],
    markets_ids = [],
    locations_ids = [],
    vendor,
  } = profile;

  const sortedRoles = getSortedCategories(roles_ids, map, isCategoryMap);
  const sortedReps = getSortedCategories(reps_ids, map, isCategoryMap);
  const sortedMarkets = getSortedCategories(markets_ids, map, isCategoryMap);
  const sortedLocations = getSortedCategories(
    locations_ids,
    map,
    isCategoryMap
  );

  const marketOptions = sortedMarkets.map((id) => ({
    value: id,
    title: getCategoryItem(id, categories, map)?.name ?? "",
  }));

  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Name</p>
        <p className={`${styles.infoValue} ${styles.pt1}`}>{name}</p>
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Role</p>
        <p className={styles.infoValue}>
          {getCategoryNames(sortedRoles).join(", ")}
        </p>
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Market</p>
        <div className={styles.marketsWrapper}>
          <ChipGroup options={marketOptions} />
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Country</p>
        <p className={`${styles.infoValue} ${styles.pt1}`}>
          {getCategoryNames(sortedLocations).join(", ")}
        </p>
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Lighting Vendor</p>
        <p className={`${styles.infoValue} ${styles.pt1}`}>{vendor}</p>
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.infoLabel}>Rep</p>
        <p className={`${styles.infoValue} ${styles.pt1}`}>
          {getCategoryNames(sortedReps).join(", ")}
        </p>
      </div>
    </div>
  );
};
