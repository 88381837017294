import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

export const ErrorMessage = ({ message }: { message?: string }) => {
  return message ? (
    <>
      <ErrorIcon />
      {message}
    </>
  ) : (
    <></>
  );
};
