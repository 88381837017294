import { authService } from "@/services/auth";
import { apiGetAuth } from "@api/auth";
import { useAppDispatch } from "@app-redux/store";
import { getUserData } from "@app-redux/thunk/userDataThunk";
import { Loader } from "@components/Loader";
import { ROUTER_PATHS } from "@constants/index";
import { Alert, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const LOGIN_ERROR = "Wrong username/password combination.";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authService.getToken("refresh_token")) {
      navigate(ROUTER_PATHS.main);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await apiGetAuth({ userName: name, password });
      if (data.access_token) {
        authService.setToken(data);
        dispatch(getUserData());
        navigate(ROUTER_PATHS.main);
      } else {
        setError(LOGIN_ERROR);
      }
    } catch (e) {
      setError(LOGIN_ERROR);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.loginContainer}>
      <Loader className="content" show={isLoading} />
      <form className={styles.loginWrapper} onSubmit={handleLogin}>
        {error && (
          <Alert
            className={styles.errorAlert}
            severity="error"
            onClose={() => setError("")}
          >
            {error}
          </Alert>
        )}
        <h1>Sign in</h1>
        <TextField
          autoComplete="off"
          autoFocus
          className={styles.customInput}
          label="Username"
          type="text"
          fullWidth
          id="outlined-basic"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          autoComplete="off"
          className={styles.customInput}
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Continue
        </Button>
      </form>
    </div>
  );
};
