const styles = {
  MuiDialog: {
    styleOverrides: {
      root: {
        ".MuiDialog-container >.MuiPaper-root": {
          borderRadius: "28px",
        },
      },
    },
  },
};

export default styles;
