import React, {
  useEffect,
  useRef,
  useState
} from "react";

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $wrapNodeInElement,
  mergeRegister
} from '@lexical/utils';
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  LexicalEditor,
} from 'lexical';

import {
  $createImageNode,
  ImageNode,
  ImagePayload,
} from '@components/Editor/nodes/ImageNode';

import { Button } from "@mui/material";
import FileInput from '@components/Editor/ui/FileInput';

export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_IMAGE_COMMAND');

const IMAGE_SIZE_ERROR = 'Image is too big, maximum is 3MB';

export function InsertImageUploadedDialogBody({
  onClick,
  onClose,
}: {
  onClose: () => void;
  onClick: (payload: InsertImagePayload) => void;
}) {
  const [src, setSrc] = useState('');

  const [error, setError] = useState('');

  const isDisabled = src === '';

  const loadImage = (files: FileList | null) => {
    setError('');
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };
  
    if (files && files.length) {
      const fileSize = files[0].size / 1024 / 1024; // in MB
      if (fileSize > 3) {
        setError(IMAGE_SIZE_ERROR);
        return;
      }
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <FileInput
        label=""
        onChange={loadImage}
        accept="image/*"
        data-test-id="image-modal-file-upload"
      >
      { error && <span className="fileInputError">{error}</span>}
      </FileInput>
      <div className="actions">
        <Button
          type="reset"
          color="secondary" 
          onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={() => onClick({altText: '', src})}>
          Confirm
        </Button>
      </div>
    </>
  );
}

export function InsertImageDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [activeEditor]);

  const onClick = (payload: InsertImagePayload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <InsertImageUploadedDialogBody onClick={onClick} onClose={onClose}/>
  );
}

export default function ImagesPlugin({
  captionsEnabled,
}: {
  captionsEnabled?: boolean;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [captionsEnabled, editor]);

  return null;
}

const TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;
