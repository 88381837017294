import {
  categorySelector,
  filtersSelector,
  isCategoryMapSelector,
  isUserDataSelector,
  profileItemSelector,
  profileListDataSelector,
} from "@app-redux/selectors";
import { setPageNumFilter } from "@app-redux/slices/filtersSlice";
import { setSelectedProfile } from "@app-redux/slices/profileListSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { CountryFlag } from "@components/CountryFlag";
import { SortMenu } from "@components/SortMenu";
import { ROUTER_PATHS } from "@constants/index";
import Avatar from "@mui/material/Avatar";
import {
  getCategoryItem,
  getSortedCategories,
  stringAvatar,
} from "@utils/index";
import React, { Suspense, lazy, memo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const NothingFound = lazy(() => import("@components/NothingFound"));

export const ProfileTableHeaderMemo = memo(function ProfileTableHeader() {
  const { data } = useAppSelector(categorySelector);
  return (
    <thead>
      <tr>
        <th className={styles.rowFilter}>
          Person <SortMenu />
        </th>
        {data.map((category) => (
          <th key={category.id}>{category.name}</th>
        ))}
      </tr>
    </thead>
  );
});

export const ProfileTableRowMemo = memo(function ProfileTableRow({
  index,
}: {
  index: number;
}) {
  const dispatch = useAppDispatch();
  const item = useAppSelector((state) => profileItemSelector(state, index));
  const { data: categoryList, map } = useAppSelector(categorySelector);
  const isCategoryMap = useAppSelector(isCategoryMapSelector);
  const {
    id = "",
    name = "",
    image_url = "",
    roles_ids = [],
    reps_ids = [],
    markets_ids = [],
    locations_ids = [],
  } = item || {};

  const sortedRoles = getSortedCategories(roles_ids, map, isCategoryMap);
  const sortedReps = getSortedCategories(reps_ids, map, isCategoryMap);
  const sortedMarkets = getSortedCategories(markets_ids, map, isCategoryMap);
  const sortedLocations = getSortedCategories(
    locations_ids,
    map,
    isCategoryMap
  );

  const getCategoryNames = (arr: string[]) =>
    arr.map((id) => getCategoryItem(id, categoryList, map)?.name).join(", ");

  const getCountryNames = (arr: string[]) => {
    return arr.map((id) => {
      const item = getCategoryItem(id, categoryList, map);
      return item ? item.name : "";
    });
  };

  const onClickHandler = () => {
    dispatch(
      setSelectedProfile({
        id: id,
        index,
      })
    );
  };

  return (
    <tr key={id}>
      <td aria-label={`Link to profile: ${name}`}>
        <div className={styles.profileName}>
          <Avatar alt={name} src={image_url} {...stringAvatar(name)} />
          <Link to={`/${ROUTER_PATHS.profile}/${id}`}>
            {<span onClick={onClickHandler}>{name}</span>}
          </Link>
        </div>
      </td>
      <td>{getCategoryNames(sortedRoles)}</td>
      <td>{getCategoryNames(sortedMarkets)}</td>
      <td>
        {getCountryNames(sortedLocations).map((item) => (
          <div key={item} className={styles.countryBlock}>
            <CountryFlag country={item}/>
            <p>{item}</p>
          </div>
        ))}
      </td>
      <td>{getCategoryNames(sortedReps)}</td>
    </tr>
  );
});

export const ProfileTable = () => {
  const dispatch = useAppDispatch();
  const isUserData = useSelector(isUserDataSelector);
  const {
    data: { data: profileList, count },
    isLoading,
  } = useAppSelector(profileListDataSelector);
  const { page = 0 } = useAppSelector(filtersSelector);
  const { isLoading: isCategoryLoading } = useAppSelector(categorySelector);
  const dataLength = profileList.length;

  const getMoreItems = () => {
    dispatch(setPageNumFilter(page + 1));
  };

  return (
    <InfiniteScroll
      scrollThreshold={0.8}
      dataLength={dataLength}
      next={getMoreItems}
      loader=""
      hasMore={count > dataLength}
      scrollableTarget="InfiniteScroll"
      endMessage=""
    >
      <table aria-label="Profile table" className={styles.table}>
        <colgroup span={5}>
          <col width="28%" />
          <col width="18%" />
          <col width="18%" />
          <col width="18%" />
          <col width="18%" />
        </colgroup>
        <ProfileTableHeaderMemo />
        <tbody>
          {profileList.map((row, index) => (
            <ProfileTableRowMemo key={row.id} index={index} />
          ))}
        </tbody>
      </table>
      {isUserData && !isLoading && !isCategoryLoading && !count && (
        <Suspense fallback="">
          <NothingFound />
        </Suspense>
      )}
    </InfiniteScroll>
  );
};
