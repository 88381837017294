import { isAdminUser } from "@app-redux/selectors";
import { useAppSelector } from "@app-redux/store";
import { ProfileMenu } from "@components/ProfileMenu";
import { ROUTER_PATHS } from "@constants/index";
import { Button, Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.css";

type Props = {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  time: string;
};

export const ProfileHeader = ({ isEditing, setIsEditing, time }: Props) => {
  const { id = "" } = useParams();
  const isNewProfile = id === ROUTER_PATHS.newProfile;
  const isAdmin = useAppSelector(isAdminUser);
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className={styles.profileHeader}>
      <div className={styles.titleBlock}>
        <h1>{isNewProfile ? "New profile" : "Profile"}</h1>
        {!isEditing && <span>Last updated {time}</span>}
      </div>
      <Stack direction="row" spacing="8px">
        { isAdmin && (!isEditing ? (
          <>
            <Button
              aria-label="Edit"
              variant="contained"
              color="inherit"
              onClick={toggleEdit}
            >
              Edit
            </Button>
            <ProfileMenu id={id} />
          </>
        ) : (
          <>
            <Button type="reset" aria-label="reset" color="primary">
              Discard
            </Button>
            <Button
              type="submit"
              aria-label="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </>
          ))
        }
      </Stack>
    </div>
  );
};
