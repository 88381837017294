import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { exportProfileList, getProfileList } from "../thunk/profileListThunk";
import { ProfileRes } from "./profileSlice";

export type ProfileListRes = { data: ProfileRes[]; count: number };

export type ProfileListState = {
  data: ProfileListRes;
  isLoading: boolean;
  error: string;
  selected?: {
    id: string;
    index: number;
  };
};

export const initialProfileListState: ProfileListState = {
  data: {data: [], count: 0},
  isLoading: false,
  error: "",
};

export const profileListSlice = createSlice({
  name: "profileList",
  initialState: initialProfileListState,
  reducers: {
    setProfileList: (state, {payload}: PayloadAction<ProfileListRes>) => {
      state.data = payload;
    },
    updateProfileAndListOrder: (
      state,
      {payload}: PayloadAction<{ data: Partial<ProfileRes> }>
    ) => {
      const index = state.data.data.findIndex((item) => item.id === payload.data.id);
      if (index !== -1) {
        state.data.data[index] = {
          ...state.data.data[index],
          ...payload.data,
        };

        if (index) {
          const profile = state.data.data[index];
          state.data.data.splice(index, 1);
          state.data.data.unshift(profile);
          state.selected = {
            index: 0,
            id: profile.id,
          };
        }
      }
    },
    addProfileOnStart: (state, {payload}: PayloadAction<ProfileRes>) => {
      state.data.data.unshift(payload);
      state.data.count = state.data.count + 1;
    },
    deleteProfileFromList: (state, {payload}: PayloadAction<string>) => {
      state.data.data = state.data.data.filter((item) => item.id !== payload);
      state.data.count = state.data.count - 1;
    },
    setNextProfileList: (state, {payload}: PayloadAction<ProfileRes[]>) => {
      state.data.data.push(...payload);
    },
    resetSelectedProfile: (state) => {
      state.selected = undefined;
    },
    resetProfileList: (state) => {
      state.data = initialProfileListState.data;
      state.error = initialProfileListState.error;
      state.isLoading = initialProfileListState.isLoading;
    },
    setSelectedProfile: (
      state,
      {payload}: PayloadAction<ProfileListState["selected"]>
    ) => {
      state.selected = payload;
    },
    setSelectedProfileById: (state, {payload}: PayloadAction<string>) => {
      const index = state.data.data.findIndex((item) => item.id === payload);
      if (index >= 0) {
        state.selected = {
          id: payload,
          index,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exportProfileList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(exportProfileList.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(exportProfileList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getProfileList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProfileList.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProfileList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  resetProfileList,
  setProfileList,
  setNextProfileList,
  setSelectedProfile,
  setSelectedProfileById,
  updateProfileAndListOrder,
  addProfileOnStart,
  deleteProfileFromList,
  resetSelectedProfile,
} = profileListSlice.actions;

export default profileListSlice.reducer;
