
import React from "react";
import NothingFound from "@components/NothingFound";
import { useLocation } from 'react-router-dom';

export const PageNotFound = () => {
  const location = useLocation();
  return (
    <NothingFound page={location.pathname}/>
  );
};
