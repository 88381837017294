import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "./styles.module.css";

type NotFoundProps = {
  profileId?: string;
  page?: string;
};

const ProfileNotFoundTemplate = () => {
  return (
    <>
      <h2>Profile is not found.</h2>
      <p>
        Try{' '}
        <Link className={styles.redirectLink} component={RouterLink} to="/">
          return to the Main page
        </Link>{' '}
        and search for needed person once more
      </p>
    </>
  );
};

const PageNotFoundTemplate = () => {
  return (
    <>
      <h2>Page is not found.</h2>
      <p>
        Try{' '}
        <Link className={styles.redirectLink} component={RouterLink} to="/">
          return to the Main page
        </Link>
      </p>
    </>
  );
};

const ResultsNotFoundTemplate = () => {
  return (
    <>
      <h2>No profiles to display</h2>
      <p>
        We couldn't find any profiles matching your query. Try another query.
      </p>
    </>
  );
};

const NothingFound = ({ profileId, page }: NotFoundProps) => {
  return (
    <div className={styles.nothingFound}>
      <div className={styles.icon}>
        {page ? <ErrorOutlineIcon /> : <AccountCircleOutlinedIcon />}
      </div>
      {profileId && ProfileNotFoundTemplate()}
      {page && PageNotFoundTemplate()}
      {!profileId && !page && ResultsNotFoundTemplate()}
    </div>
  );
};

export default NothingFound;
