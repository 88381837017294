import { categorySelector, filtersSelector } from "@app-redux/selectors";
import { CategoryMap, CategoryRes } from "@app-redux/slices/categorySlice";
import {
  resetFilters,
  setSearchFilter,
  updateFilterCategoriesArr,
} from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { ChipGroupProps } from "@components/ChipGroup";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, Chip, Divider } from "@mui/material";
import { getCategoryItem } from "@utils/index";
import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { CATEGORY_INDEX_MAP } from "@constants/index";

const MARGIN = 16;

const getMapWithRepPriority = ([cIndex, iIndex]: [number, number]) => {
  if (cIndex === CATEGORY_INDEX_MAP.REP) return [0, iIndex];
  if (cIndex < CATEGORY_INDEX_MAP.LOCATION) return [+cIndex, iIndex];
  return [cIndex, iIndex];
};

const getOptions = (
  data: CategoryRes,
  map: CategoryMap,
  search_term = "",
  categories_values = [] as string[]
): ChipGroupProps["options"] => {
  const options: ChipGroupProps["options"] = [];
  if (search_term) options.push({ title: search_term, value: "search" });

  if (categories_values.length) {
    const sortedCategories: ChipGroupProps["options"] = categories_values
      .sort((a, b) => {
        if (!map[a] || !map[b]) return 0;
        const [categoryIndexA, itemIndexA] = getMapWithRepPriority(map[a]);
        const [categoryIndexB, itemIndexB] = getMapWithRepPriority(map[b]);
        if (categoryIndexA === categoryIndexB) {
          return itemIndexA - itemIndexB;
        }
        return categoryIndexA - categoryIndexB;
      })
      .map((id) => {
        const { name: title = "", id: value = "" } =
          getCategoryItem(id, data, map) || {};
        return { title, value };
      });
    options.push(...sortedCategories);
  }

  return options;
};

type Props = {
  setScrollHeight: React.Dispatch<React.SetStateAction<number>>;
};

export const FiltersChipPanel = ({ setScrollHeight }: Props) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { data, map } = useAppSelector(categorySelector);
  const filters = useAppSelector(filtersSelector);

  const onChipDelete = (id: string) => {
    if (id === "search") {
      dispatch(setSearchFilter(""));
    } else {
      dispatch(updateFilterCategoriesArr({ id, checked: true }));
    }
  };

  const onReset = () => {
    dispatch(resetFilters());
  };

  const validateCategories = (): string[] | undefined => {
    if (!data.length) return [];
    return filters?.categories_values?.filter((id) => {
      const isValid = map[id];
      if (!isValid) {
        dispatch(updateFilterCategoriesArr({ id, checked: true }));
      }
      return isValid;
    });
  };

  const categories = validateCategories();

  const options: ChipGroupProps["options"] = data.length
    ? getOptions(data, map, filters.search_term, categories)
    : [];

  useEffect(() => {
    const height = ref?.current?.clientHeight ?? 0;
    setScrollHeight(height ? height + MARGIN : 0);
  }, [options.length]);

  return options.length ? (
    <div className={styles.chipsPanel} ref={ref}>
      <Button color="secondary" onClick={onReset} aria-label="Reset filters">
        Clear All
      </Button>

      <Divider orientation="vertical" flexItem className={styles.divider} />
      {options.map(({ title, value: id }) => {
        return (
          <Chip
            key={id}
            label={title}
            className={styles.chipLabel}
            onDelete={() => onChipDelete(id)}
            deleteIcon={<ClearIcon />}
          />
        );
      })}
    </div>
  ) : (
    <></>
  );
};
