import { authService } from "@/services/auth";
import { AUTH_PATH, REFRESH_PATH, apiRefreshAuth } from "@api/auth";
import { api } from "@api/axiosInstance";
import { resetCategory } from "@app-redux/slices/categorySlice";
import { resetFilters } from "@app-redux/slices/filtersSlice";
import { resetProfileList } from "@app-redux/slices/profileListSlice";
import { resetProfile } from "@app-redux/slices/profileSlice";
import { resetUserData } from "@app-redux/slices/userDataSlice";
import { useAppDispatch } from "@app-redux/store";
import { ROUTER_PATHS } from "@constants/index";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loginRedirect = () => {
    authService.removeToken();
    dispatch(resetUserData());
    dispatch(resetProfileList());
    dispatch(resetProfile());
    dispatch(resetFilters());
    dispatch(resetCategory());
    navigate(ROUTER_PATHS.login);
  };

  useEffect(() => {
    if (!authService.getToken("refresh_token")) loginRedirect();
    const request = api.interceptors.request.use(
      (res) => {
        if (res.url !== REFRESH_PATH && res.url !== AUTH_PATH) {
          res.headers.Authorization = authService.getToken("access_token");
        }
        return res;
      },
      (error) => Promise.reject(error)
    );

    const response = api.interceptors.response.use(
      (res) => res,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          const refresh_token = authService.getToken("refresh_token");
          if (
            originalRequest.url === REFRESH_PATH ||
            (!refresh_token && originalRequest.url !== AUTH_PATH)
          ) {
            loginRedirect();
            return Promise.reject(error);
          }
          if (!originalRequest._retry && refresh_token) {
            originalRequest._retry = true;
            delete originalRequest.headers.Authorization;
            if (authService.isLoading) {
              const token = await authService.awaitToken("access_token");
              originalRequest.headers.Authorization = token;
              authService.isLoading = false;
              return api.request(originalRequest);
            }
            try {
              authService.isLoading = true;
              const data = await apiRefreshAuth(refresh_token);
              authService.isLoading = false;
              if (data.access_token) {
                authService.setToken(data);
                originalRequest.headers.Authorization = data.access_token;
                return api.request(originalRequest);
              } else {
                loginRedirect();
                return Promise.reject(error);
              }
            } catch (e) {
              console.log(e);
              loginRedirect();
              return Promise.reject(error);
            }
          }
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      authService.isLoading = false;
      api.interceptors.response.eject(response);
      api.interceptors.request.eject(request);
    };
  }, []);
};
