import React, { useRef, useCallback, useEffect, useState } from 'react';

import {$isLinkNode} from '@lexical/link';

import {
  mergeRegister,
} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  RangeSelection,
} from 'lexical';


import { getSelectedNode } from '@components/Editor/utils/getSelectedNode';

import { positionEditorElement } from '@components/Editor/utils/positionEditorElement'
import { ExternalLinkEditorComponent } from '@components/Editor/components/ExternalLinkEditorComponent'
import { InternalLinkEditorComponent } from '@components/Editor/components/InternalLinkEditorComponent'

const LowPriority = 1;

export function FloatingLinkEditor({ editor, isInnerLink }: { editor: LexicalEditor,  isInnerLink: boolean}) {
    const LINK_PREFIX = 'https://';
    const editorRef = useRef<HTMLDivElement | null>(null);

    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [linkTitle, setLinkTitle] = useState("");
    const [lastSelection, setLastSelection] = useState<RangeSelection | null>(
      null
    );

    const updateLinkEditor = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const parent = node.getParent();
        if ($isLinkNode(parent)) {
          const link = parent.getURL();
          const title = parent.getTitle();
          if (link && link !== LINK_PREFIX) {
            setLinkUrl(link);
          }
          if (title) {
            setLinkTitle(title);
          }
        } else if ($isLinkNode(node)) {
          setLinkUrl(node.getURL());
        } else {
          setLinkUrl("");
          setLinkTitle("");
        }
      }
      const editorElem = editorRef.current;
      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;
  
      if (editorElem === null) {
        return;
      }
  
      const rootElement = editor.getRootElement();
      if (
        selection !== null &&
        !nativeSelection?.isCollapsed &&
        rootElement !== null &&
        rootElement.contains(nativeSelection?.anchorNode as Node)
      ) {
        const domRange = nativeSelection?.getRangeAt(0);
        let rect;
        if (nativeSelection?.anchorNode === rootElement) {
          let inner: Element = rootElement;
          while (inner.firstElementChild != null) {
            inner = inner.firstElementChild;
          }
          rect = inner.getBoundingClientRect() ?? null;
        } else {
          rect = domRange?.getBoundingClientRect() ?? null;
        }
  
        if (!mouseDownRef.current) {
          positionEditorElement(editorElem, rect);
        }
        setLastSelection(selection as RangeSelection);
      } else if (!activeElement || activeElement.className !== "link-input") {
        positionEditorElement(editorElem, null);
        setLastSelection(null);
        setLinkUrl("");
      }
  
      return true;
    }, [editor]);
  
    useEffect(() => {
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),
  
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          LowPriority
        )
      );
    }, [editor, updateLinkEditor]);
  
    useEffect(() => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    }, [editor, updateLinkEditor]);
  
    return (
      <div ref={editorRef} className="link-editor">
        { 
          !isInnerLink 
          && 
          <ExternalLinkEditorComponent
            editor={editor}
            linkUrl={linkUrl}
            lastSelection={lastSelection}
            setLinkUrl={setLinkUrl}
          />}
          { 
          isInnerLink 
          && 
          <InternalLinkEditorComponent
            editor={editor}
            linkUrl={linkUrl}
            lastSelection={lastSelection}
            setLinkUrl={setLinkUrl}
            linkTitle={linkTitle}
          />}
      </div>
    );
  }