const styles = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: "var(--gray-3)",
      },
    },
  },
};

export default styles;
