import { categorySelector } from "@app-redux/selectors";
import { useAppSelector } from "@app-redux/store";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";
import { getCategoryItem } from "@utils/index";
import React from "react";

export type Rep = {
  value: string;
  title: string;
};

type Props = {
  onChange: (value: string[]) => void;
  value?: string[];
  options: Rep[];
};

const renderOption = (props: any, option: Rep, { selected } : any) => (
  <MenuItem {...props}>
    <Checkbox 
      aria-label={option.title}
      style={{ marginRight: 8 }}
      sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={selected} />
    {option.title}
  </MenuItem>
);

export const RepSelect = ({ onChange, value = [], options }: Props) => {
  const { data: categoryList, map } = useAppSelector(categorySelector);
  const getValues = () => {
    if (!value.length) return [];
    const values: Rep[] = [];
    value.forEach((item: string) => {
      const obj = {
        title: getCategoryItem(item, categoryList, map)?.name ?? "",
        value: item,
      }
      values.push(obj);
    });
    return values;
  }
  return (
    <Autocomplete
        multiple
        disablePortal
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title ?? ""}
        value={getValues()}
        onChange={(event, newValue, reason, details) => {
          const updatedValue: string[] = [];
          newValue.forEach(item => {
            updatedValue.push(item.value);
          });
          onChange(updatedValue);
        }}
        isOptionEqualToValue={(option, value) => {
          if (!value) return true;
          return option.value === value.value;
        }}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" type="text" placeholder="Chose Rep(s)" />
        )}
        renderOption={renderOption}
    />
  );
};
