import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import categorySlice from "./slices/categorySlice";
import filtersSlice from "./slices/filtersSlice";
import profileListSlice from "./slices/profileListSlice";
import profileSlice from "./slices/profileSlice";
import userDataSlice from "./slices/userDataSlice";

const filterPersistConfig = { key: "filter", storage: storageSession };

const rootReducer = combineReducers({
  profileList: profileListSlice,
  profile: profileSlice,
  category: categorySlice,
  filter: persistReducer(filterPersistConfig, filtersSlice),
  userData: userDataSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({ reducer: rootReducer, preloadedState });
}
