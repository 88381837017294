import {
  categorySelector,
  filtersSelector,
  isCategoryMapSelector,
  isUserDataSelector,
} from "@app-redux/selectors";
import { setPageNumFilter } from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { getProfileList } from "@app-redux/thunk/profileListThunk";
import { getUserData } from "@app-redux/thunk/userDataThunk";
import { AppRouter } from "@components/AppRouter";
import React, { useEffect } from "react";
import { useAuth } from "./hooks/useAuth";
import "./style/index.css";
import { getCategoryFilters } from "./utils";

let isFirstRender = true;

export default function App() {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filtersSelector);
  const { map } = useAppSelector(categorySelector);
  const isUserData = useAppSelector(isUserDataSelector);
  const isCategoryMap = useAppSelector(isCategoryMapSelector);

  useAuth();

  useEffect(() => {
    if (isUserData) return;
    dispatch(getUserData());
  }, [isUserData]);

  useEffect(() => {
    if (!isCategoryMap) return;
    if (isFirstRender && filters.page) {
      dispatch(setPageNumFilter(0));
    } else {
      const { categories_values, ...rest } = filters;
      const categoryFilters = getCategoryFilters(map, categories_values);
      dispatch(getProfileList({ ...rest, ...categoryFilters }));
    }
    isFirstRender = false;
  }, [filters, isCategoryMap]);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}
