import { apiGetCategory } from "@/api/category";
import { CategoryRes, setCategory } from "@app-redux/slices/categorySlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { UserFavorites } from "@app-redux/slices/userDataSlice";

export const getCategory = createAsyncThunk<CategoryRes, UserFavorites>(
  "category/get",
  async (favorites, thunkAPI) => {
    try {
      const data = await apiGetCategory();
      thunkAPI.dispatch(setCategory({ data, favorites }));
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message);
    }
  }
);
