import { CategoryRes } from "@app-redux/slices/categorySlice";
import { api } from "./axiosInstance";

export const apiGetCategory = async (): Promise<CategoryRes> => {
  try {
    const response = await api.get("/api/category");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteCategoryItem = async (id: string): Promise<void> => {
  try {
    await api.delete("/api/category/value", { data: [id] });
  } catch (error) {
    throw error;
  }
};

type CreateCategoryItemProps = {
  id: string;
  items: [
    {
      name: string;
    }
  ];
};

export const apiPostCategoryItem = async (
  params: CreateCategoryItemProps
): Promise<CategoryRes> => {
  try {
    const response = await api.post("/api/category/value", params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

type UpdateCategoryItemProps = {
  id: string;
  value: string;
};

export const apiPatchCategoryItem = async ({
  id,
  value,
}: UpdateCategoryItemProps): Promise<void> => {
  try {
    await api.patch(`/api/category/value/${id}`, value);
  } catch (error) {
    throw error;
  }
};
