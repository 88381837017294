export enum ROUTER_PATHS {
  main = "/",
  profile = "profile",
  newProfile = "new-profile",
  login = "login",
  categorySettings = "category-settings",
}

export enum SORT_TYPE {
  asc = "Asc",
  desc = "Desc",
}

export enum SORT_BY {
  updatedAt = "UpdatedAt",
  name = "Name",
}

export enum SEARCH_FOR {
  pageLink = "PageLink",
  relevance = "relevance",
}

export const DEFAULT_PAGE_LIST_PORTION = 20;

export const CATEGORY_INDEX_MAP = {
  ROLE: 0,
  MARKET: 1,
  LOCATION: 2,
  REP: 3,
} as const;

export const FAVORITE_MAP = {
  Market: "favorite_markets",
  Country: "favorite_locations",
  Role: "favorite_roles",
  Rep: "favorite_reps",
} as const;

export const USER_ROLE = {
  admin: "WikiAdmin",
  reader: "WikiReader",
} as const;

export const CATEGORY_MAP = {
  Location: "Country",
} as const;
