import { ProfileRes } from "@app-redux/slices/profileSlice";
import { api } from "./axiosInstance";

export const apiGetProfile = async (id: string): Promise<ProfileRes> => {
  try {
    const response = await api.get(`/api/page/profile/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export type ProfileCreateParams = {
  raw_page_content?: string;
  page_content?: ProfileRes["content"];
  name: string;
  roles_ids?: string[];
  reps_ids?: string[];
  markets_ids?: string[];
  locations_ids?: string[];
  vendor?: string;
  account_representative?: string;
  image?: string;
  created_at?: string;
  updated_at?: string;
};

export type ProfileCreateRes = {
  profile_id: string;
  image_url?: string;
};

export const apiPostProfile = async (
  params: ProfileCreateParams
): Promise<ProfileCreateRes> => {
  try {
    const response = await api.post(`/api/page/profile`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export type ProfileUpdateParams = {
  id: string;
  params: Partial<ProfileCreateParams>;
};
export type ProfileUpdateRes = string;

export const apiPatchProfile = async ({
  id,
  params,
}: ProfileUpdateParams): Promise<ProfileUpdateRes> => {
  try {
    const response = await api.patch(`/api/page/profile/${id}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteProfile = async (id: string) => {
  try {
    await api.delete(`/api/page/profile/${id}`);
  } catch (error) {
    throw error;
  }
};
