const styles = {
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        ".MuiButtonBase-root, a, p": {
          fontSize: "var(--text-body-1)",
          fontWeight: "var(--font-weight-normal)",
          lineHeight: "142.857%",
          letterSpacing: "0.25px",
        },
        ".MuiButtonBase-root": {
          fontWeight: "var(--font-weight-medium)",
        },
        a: {
          color: "var(--gray-5)",
        },
        p: {
          color: "var(--text-title-1)",
        },
        ".MuiBreadcrumbs-separator": {
          margin: "0 12px 0 0",
        },
        ".MuiBreadcrumbs-separator svg": {
          width: "20px",
          height: "20px",
        },
      },
    },
  },
};

export default styles;
