import { getCategory } from "@app-redux/thunk/categoryThunk";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { sortCategoryItems } from "@utils/index";
import type { UserFavorites } from "./userDataSlice";
import { CATEGORY_MAP } from "@constants/index";

export type CategoryItem = {
  id: string;
  name: string;
  count: number;
};

export type Category = {
  id: string;
  name: string;
  items: CategoryItem[];
};

export type CategoryRes = Array<Category>;

export type CategoryMap = Record<string, [number, number]>;

export type CategoryState = {
  data: CategoryRes;
  map: CategoryMap;
  isLoading: boolean;
  error: string;
};

export const initialCategoryState: CategoryState = {
  data: [],
  map: {},
  isLoading: false,
  error: "",
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    resetCategory: (state) => {
      state.data = initialCategoryState.data;
      state.map = initialCategoryState.map;
      state.isLoading = initialCategoryState.isLoading;
      state.error = initialCategoryState.error;
    },
    setCategory: (
      state,
      {
        payload,
      }: PayloadAction<{ data: CategoryRes; favorites: UserFavorites }>
    ) => {
      const sortedPayload = payload.data.map(
        ({ items = [], id, name }, categoryIndex) => {
          const categoryName = CATEGORY_MAP[name] || name;
          const sortItems = sortCategoryItems(items, payload.favorites, categoryName);
          sortItems.forEach((item, itemIndex) => {
            state.map[item.id] = [categoryIndex, itemIndex];
          });
          return { id, name: categoryName, items: sortItems };
        }
      );

      state.data = sortedPayload;
    },
    sortCategory: (state, { payload }: PayloadAction<UserFavorites>) => {
      const sortedPayload = state.data.map(
        ({ items = [], id, name }, categoryIndex) => {
          const sortItems = sortCategoryItems(items, payload, name);
          sortItems.forEach((item, itemIndex) => {
            state.map[item.id] = [categoryIndex, itemIndex];
          });

          return { id, name, items: sortItems };
        }
      );
      state.data = sortedPayload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategory.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setCategory, sortCategory, resetCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
