import { Layout } from "@components/Layout";
import { ROUTER_PATHS } from "@constants/index";
import { CategorySettings } from "@pages/CategorySettings";
import { Login } from "@pages/Login";
import { PageNotFound } from "@pages/PageNotFound";
import { Main } from "@pages/Main";
import { Profile } from "@pages/Profile";
import { isAdminUser, isUserDataSelector } from "@app-redux/selectors";
import { useAppSelector } from "@app-redux/store";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export const AppRouter = () => {
  const isAdmin = useAppSelector(isAdminUser);
  const isUserData = useAppSelector(isUserDataSelector);
  return (
    <Routes>
      <Route path={ROUTER_PATHS.main} element={<Layout />}>
        <Route index element={<Main />} />
        <Route path={`${ROUTER_PATHS.profile}/:id`} element={<Profile />} />
        <Route path={ROUTER_PATHS.login} element={<Login />} />
        {isUserData && !isAdmin &&
          <Route
            path={`${ROUTER_PATHS.profile}/${ROUTER_PATHS.newProfile}`}
            element={<Navigate to={ROUTER_PATHS.main} />}
          />
        }
        <Route
          path={ROUTER_PATHS.categorySettings}
          element={<CategorySettings />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
