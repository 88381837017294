import { categorySelector } from "@app-redux/selectors";
import { useAppSelector } from "@app-redux/store";
import { CountryFlag } from "@components/CountryFlag";
import { MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getCategoryItem } from "@utils/index";
import React from "react";

export type Country = {
  value: string;
  title: string;
};

type Props = {
  onChange: (value: string[]) => void;
  value?: string[];
  options: Country[];
};

export const CountrySelect = ({ onChange, value = [], options }: Props) => {
  const { data: categoryList, map } = useAppSelector(categorySelector);

  return (
    <Autocomplete
      clearIcon={null}
      disablePortal
      renderInput={(params) => (
        <TextField {...params} autoComplete="off" type="text" placeholder="Choose Main Country" />
      )}
      options={options}
      value={
        value && value[0]
          ? {
              title: getCategoryItem(value[0], categoryList, map)?.name ?? "",
              value: value[0],
            }
          : null
      }
      isOptionEqualToValue={(option, value) => {
        if (!value) return true;
        return option.value === value.value;
      }}
      onChange={(event, newValue, reason, details) => {
        let updatedValue: string[] = [];
        if (newValue?.value !== value[0]) {
          updatedValue = newValue?.value ? [newValue.value] : [];
        }
        onChange(updatedValue);
      }}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value}>
          <CountryFlag country={option.title} />
          {option.title}
        </MenuItem>
      )}
      getOptionLabel={(option) => option.title ?? ""}
    />
  );
};
