import {categorySelector, filtersSelector, isAdminUser, profileListDataSelector} from "@app-redux/selectors";
import {useAppDispatch, useAppSelector} from "@app-redux/store";
import {FiltersChipPanel} from "@components/FiltersChipPanel";
import {Loader} from "@components/Loader";
import {ProfileTable} from "@components/ProfileTable";
import {Search} from "@components/Search";
import {ROUTER_PATHS} from "@/constants";
import AddIcon from "@mui/icons-material/Add";
import FileDownload from "@mui/icons-material/FileDownload";
import {Button} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import styles from "./styles.module.css";
import {getCategoryFilters} from "@/utils";
import {exportProfileList} from "@/redux/thunk/profileListThunk";

export const ResultsPanel = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filtersSelector);
  const {map} = useAppSelector(categorySelector);


  const {
    data: {count},
    isLoading,
  } = useAppSelector(profileListDataSelector);
  const {
    page = 0,
  } = useAppSelector(filtersSelector);
  const [scrollHeight, setScrollHeight] = useState(0);
  const {data} = useAppSelector(categorySelector);
  const scrollHeightValue = `calc(100% - 40px - 16px - ${scrollHeight}px)`;
  const isAdmin = useAppSelector(isAdminUser);
  return (
    <div className={styles.container}>
      <div className={styles.topPanel}>
        <h1>All Profiles</h1>
        <Search className={styles.search}/>
        <span className={styles.resultsLabel}>{count} results</span>
        <div className={styles.actionsBlock}>
          {isAdmin &&
              <Button
                  aria-label="Export"
                  variant="contained"
                  color="inherit"
                  className={styles.actionButton}
                  startIcon={<FileDownload/>}
                  onClick={() => {
                    const {categories_values, ...rest} = filters;
                    const categoryFilters = getCategoryFilters(map, categories_values);
                    dispatch(exportProfileList({...rest, ...categoryFilters}));
                  }}
              >
                  Export
              </Button>}
          {isAdmin && <Button
              component={Link}
              to={`/${ROUTER_PATHS.profile}/${ROUTER_PATHS.newProfile}`}
              className={styles.actionButton}
              aria-label="Create profile"
              variant="contained"
              startIcon={<AddIcon/>}
          >
              Create
          </Button>}
        </div>
      </div>
      <FiltersChipPanel setScrollHeight={setScrollHeight}/>
      <div
        style={{
          height: scrollHeightValue,
          position: "relative",
        }}
      >
        <Loader
          className={"profileList"}
          show={(!page && isLoading) || !data.length}
          skipWait
        />
        <div
          id="InfiniteScroll"
          style={{
            height: "100%",
            overflowX: "auto",
          }}
        >
          <ProfileTable/>
        </div>
      </div>
    </div>
  );
};
