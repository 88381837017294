const styles = {
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "32px",
        color: "var(--gray-3)",
      },
    },
  },
};

export default styles;
