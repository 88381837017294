const styles = {
  MuiListItemButton: {
    styleOverrides: {
      root: {
        paddingTop: "7px",
        paddingBottom: "7px",
      },
    },
  },
};

export default styles;
