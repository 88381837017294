import { filtersSelector } from "@app-redux/selectors";
import { setSearchFilter } from "@app-redux/slices/filtersSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { ErrorMessage } from "@components/ErrorMessage";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect } from "react";
import styles from "./styles.module.css";

export const Search = ({ className }: { className: string }) => {
  const dispatch = useAppDispatch();
  const { search_term } = useAppSelector(filtersSelector);
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState(search_term);

  useEffect(() => {
    if (search_term !== value) {
      setValue(search_term);
      setError(false);
    }
  }, [search_term]);

  const validate = (value?: string) => {
    const isErrors = value ? value.length < 3 : false;
    if (isErrors) {
      setError(true);
    } else {
      setError(false);
    }

    return !isErrors;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setValue(e.target.value);
  };

  const search = () => {
    if (validate(value)) {
      dispatch(setSearchFilter(value ?? ""));
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !error) {
      search();
    }
  };

  return (
    <TextField
      value={value || ""}
      error={error}
      helperText={<ErrorMessage message={error ? "min 3 symbols" : ""} />}
      onChange={onChange}
      onKeyDown={onKeyDown}
      type="text"
      className={`${className} ${styles.searchField}`}
      placeholder="Search"
      aria-label="Search input"
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" onClick={search} sx={{ width: '22px' }}>
            <IconButton
              edge="start"
              className={styles.searchIcon}
              aria-label="Search"
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
