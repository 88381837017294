import {
  getUserData,
  updateFavoriteThunk,
} from "@app-redux/thunk/userDataThunk";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { USER_ROLE } from "@constants/index";

export type UserRoles = (typeof USER_ROLE)[keyof typeof USER_ROLE];

export type UserFavorites = {
  favorite_locations?: string[],
  favorite_roles?: string[],
  favorite_reps?: string[],
  favorite_markets?: string[],
}

export type UserDataRes = {
  id: string;
  full_name: string;
  preferences: UserFavorites;
  roles: Array<UserRoles>;
  isAdmin: boolean;
};

export type UserDataState = {
  error: string;
  data?: UserDataRes;
  isLoading: boolean;
};

const initialState: UserDataState = {
  error: "",
  isLoading: false,
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    setUserData: (
      state,
      { payload }: PayloadAction<UserDataRes | undefined>
    ) => {
      state.data = payload;
    },
    resetUserData: (state) => {
      delete state.data;
      state.error = "";
      state.isLoading = false;
    },
    updateFavorites: (
      state,
      { payload }: PayloadAction<UserFavorites>
    ) => {
      if (!state.data) return;
      const { favorite_locations, favorite_roles, favorite_reps, favorite_markets } = payload;
      state.data.preferences.favorite_locations = favorite_locations;
      state.data.preferences.favorite_roles = favorite_roles;
      state.data.preferences.favorite_reps = favorite_reps;
      state.data.preferences.favorite_markets = favorite_markets;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateFavoriteThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFavoriteThunk.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFavoriteThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setUserData, updateFavorites, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
