import type {
  CategoryItem,
  CategoryMap,
  CategoryRes,
} from "@app-redux/slices/categorySlice";
import type { Filter } from "@app-redux/slices/filtersSlice";
import type { ProfileRes } from "@app-redux/slices/profileSlice";
import type { UserFavorites } from "@app-redux/slices/userDataSlice";
import type { ProfileNewDataObject } from "@app-redux/thunk/profileThunk";
import { FAVORITE_MAP } from "@constants/index";

export const getCategoryItem = (
  id: string,
  data: CategoryRes,
  map: CategoryMap
): CategoryItem | undefined => {
  if (!map[id]) return;
  const [categoryIndex, itemIndex] = map[id];
  return data[categoryIndex].items[itemIndex];
};

export function formatDate(dateStr?: string): string {
  if (!dateStr) return "";
  const date = new Date(dateStr);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  return date.toLocaleDateString("en-US", options);
}

export const parseJson = (str: string) => {
  if (!str) return;
  try {
    return JSON.parse(str);
  } catch (error) {
    console.log(error);
  }
};

export const stringAvatar = (name: string) => {
  const arr = name.split(" ");
  const firstLetter = arr[0][0];
  const getNameAbbreviation =
    arr.length < 2 ? firstLetter : `${firstLetter}${arr[arr.length - 1][0]}`;
  return {
    children: getNameAbbreviation,
  };
};

export const updateCategoriesCount = async (
  {
    markets_ids,
    locations_ids,
    roles_ids,
    reps_ids,
  }: ProfileNewDataObject["data"],
  func: () => Promise<any>
) => {
  if (markets_ids || locations_ids || roles_ids || reps_ids) {
    await func();
  }
};

export const sortCategoryItems = (
  items: CategoryItem[],
  favorites: UserFavorites,
  name: string
) => {
  const arr = favorites[FAVORITE_MAP[name]];
  return items.slice().sort((a, b) => {
    if (arr && arr.length) {
      const isA = arr.includes(a.id);
      const isB = arr.includes(b.id);
      if (isA && isB) return a.name.localeCompare(b.name);
      if (isA) return -1;
      if (isB) return 1;
    }
    return a.name.localeCompare(b.name);
  });
};

export const getCategoryFilters = (
  map: CategoryMap,
  categories_values: Filter["categories_values"]
): {
  roles_ids?: ProfileRes["roles_ids"];
  reps_ids?: ProfileRes["reps_ids"];
  markets_ids?: ProfileRes["markets_ids"];
  locations_ids?: ProfileRes["locations_ids"];
} => {
  if (categories_values?.length) {
    const values = categories_values.reduce(
      (acc, id) => {
        const itemData = map[id];
        if (!itemData) return acc;
        if (itemData[0] === 0) {
          acc.roles_ids = [...(acc.roles_ids || []), id.toString()];
        } else if (itemData[0] === 1) {
          acc.markets_ids = [...(acc.markets_ids || []), id.toString()];
        } else if (itemData[0] === 2) {
          acc.locations_ids = [...(acc.locations_ids || []), id.toString()];
        } else if (itemData[0] === 3) {
          acc.reps_ids = [...(acc.reps_ids || []), id.toString()];
        }
        return acc;
      },
      {
        roles_ids: [] as string[],
        reps_ids: [] as string[],
        markets_ids: [] as string[],
        locations_ids: [] as string[],
      } as ReturnType<typeof getCategoryFilters>
    );
    return values;
  } else {
    return {};
  }
};

export const getSortedCategories = (
  arr: string[],
  map: CategoryMap,
  isMap: boolean
): string[] => {
  if (!isMap) return arr;
  const sortedItems = [...arr].sort((a, b) => {
    if (!map[a] || !map[b]) return -1;
    return map[a][1] > map[b][1] ? 1 : -1;
  });
  return sortedItems;
};
