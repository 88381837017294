const styles = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        ".MuiInputBase-root": {
          borderRadius: "12px",
          padding: "0",
        },
        ".MuiInputBase-input.MuiAutocomplete-input": {
          padding: "8px 16px",
          height: "1.5em",
          letterSpacing: "0.5px",
        },
        "&  + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox": {          
          maxHeight: "calc(7 * 56px)",
          padding: "0",
        },
        "&  + .MuiAutocomplete-popperDisablePortal li ": {
          minHeight: "56px",
          boxSize: "border-box",
          padding: "8px 16px",
          height: "1.5em",
          letterSpacing: "0.5px",
        },
      },
    },
  },
};

export default styles;
