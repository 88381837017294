import { UserDataState } from "@app-redux/slices/userDataSlice";
import type { CategoryState } from "../slices/categorySlice";
import type { FilterState } from "../slices/filtersSlice";
import type { ProfileListState } from "../slices/profileListSlice";
import type { ProfileRes, ProfileState } from "../slices/profileSlice";
import type { UserFavorites } from "../slices/userDataSlice";
import type { RootState } from "../store";

export const profileSelector = (state: RootState): ProfileState =>
  state.profile;

export const profileListDataSelector = (state: RootState): ProfileListState =>
  state.profileList;

export const categorySelector = (state: RootState): CategoryState =>
  state.category;

export const isCategoryMapSelector = (state: RootState): boolean =>
  state.category.map && Object.keys(state.category.map).length > 0;

export const profileItemSelector = (
  state: RootState,
  index: number
): ProfileRes | undefined => state.profileList.data.data[index];

export const filtersSelector = (state: RootState): FilterState["category"] =>
  state.filter.category;

export const userDataSelector = (state: RootState): UserDataState =>
  state.userData;

export const isUserDataSelector = (state: RootState): boolean =>
  Boolean(state.userData.data);

export const userNameSelector = (state: RootState): string | undefined =>
  state.userData.data?.full_name;

export const isAdminUser = (state: RootState): string | undefined =>
  state.userData.data?.isAdmin;  

export const favoritesSelector = (
  state: RootState
): UserFavorites | {
  favorite_locations: [],
  favorite_roles: [],
  favorite_reps: [],
  favorite_markets: []
} => state.userData.data?.preferences;
