import { ProfileCreateParams, ProfileCreateRes } from "@/api/profile";
import { ConditionalWrap } from "@/hoc/ConditionalWrap";
import {
  favoritesSelector,
  isUserDataSelector,
  profileSelector,
} from "@app-redux/selectors";
import { ProfileRes, resetProfileError } from "@app-redux/slices/profileSlice";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { getCategory } from "@app-redux/thunk/categoryThunk";
import {
  ProfileNewDataObject,
  createProfile,
  getProfile,
  updateProfileData,
} from "@app-redux/thunk/profileThunk";
import defaultImage from "@assets/default-profile-logo.png";
import { Breadcrumbs } from "@components/Breadcrumbs";
import Editor, { EditorStateChangeProps } from "@components/Editor/Editor";
import { DEFAULT_EDITOR_STATE } from "@components/Editor/constants";
import { Loader } from "@components/Loader";
import NothingFound from "@components/NothingFound";
import PreContentForm from "@components/PreContentForm";
import { ProfileHeader } from "@components/ProfileHeader";
import { ProfileInfo } from "@components/ProfileIinfo";
import { ProfileImage } from "@components/ProfileImage";
import { ROUTER_PATHS } from "@constants/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDate, parseJson, updateCategoriesCount } from "@utils/index";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import * as yup from "yup";
import styles from "./styles.module.css";

const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is required")
    .test("Impossible characters", "Don’t use #, %, @, /", (val) => !val.match(/[%@#\/]/)),
});

export type FormParams = Partial<Omit<ProfileRes, "id" | "content">> & {
  name: string;
};

const defaultProfileFormValues: FormParams = {
  name: "",
  roles_ids: [],
  reps_ids: [],
  markets_ids: [],
  locations_ids: [],
  vendor: "",
  account_representative: "",
};

const EMPTY_EDITOR_STATE = {
  editor_state: JSON.stringify(DEFAULT_EDITOR_STATE),
  raw_page_content: "",
  page_content: "",
};

export const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = "" } = useParams();
  const favorites = useAppSelector(favoritesSelector);
  const isUserData = useSelector(isUserDataSelector);
  const isNewProfile = id === ROUTER_PATHS.newProfile;
  const [isEditing, setIsEditing] = useState(isNewProfile ? true : false);
  const [isSave, setIsSave] = useState(false);

  const { data: profilesData, error } = useAppSelector(profileSelector);
  const isProfileData = Boolean(profilesData[id]);

  const profile: ProfileRes | FormParams | undefined = isNewProfile
    ? defaultProfileFormValues
    : profilesData[id];

  const profileEditorState = {
    editor_state: JSON.stringify(
      (profile as ProfileRes)?.content ?? DEFAULT_EDITOR_STATE
    ),
    raw_page_content: "",
    page_content: "",
  };

  const [editorState, setEditorState] = useState<EditorStateChangeProps>(
    isNewProfile ? EMPTY_EDITOR_STATE : profileEditorState
  );

  const [editorKey, setEditorKey] = useState("editorKey");

  const oldProfileImage = profile?.image_url || defaultImage;

  const [profileImage, setProfileImage] = useState(oldProfileImage);

  useEffect(() => {
    if (!isUserData || isNewProfile) return;
    if (!isProfileData) {
      dispatch(getProfile(id));
    }
  }, [id, isUserData]);

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: (profile ?? defaultProfileFormValues) as FormParams,
  });

  const updateEditor = (state: EditorStateChangeProps) => {
    setEditorState(state);
    setEditorKey(`${v4()}`);
  };

  useEffect(() => {
    if (!isNewProfile && profilesData[id]?.content) {
      const content = JSON.stringify(profilesData[id]?.content);
      updateEditor({
        editor_state: content,
        raw_page_content: "",
        page_content: "",
      });
    }
    if (!isNewProfile && profilesData[id]) {
      setProfileImage(oldProfileImage);
      Object.keys(profilesData[id]).forEach((key) => {
        if (["id", "content"].includes(key)) return;
        setValue(
          key as keyof FormParams,
          profilesData[id]![key as keyof FormParams]
        );
      });
    }
  }, [isNewProfile, profilesData[id]]);

  useEffect(() => {
    return (): void => {
      if (error) dispatch(resetProfileError());
    };
  }, [error]);

  if (error) {
    return <NothingFound profileId={id} />;
  }

  const getDirtyFields = (data: FormParams) => {
    const dirtyFields: FormParams = {} as FormParams;
    Object.keys(data).forEach((key) => {
      const dataValue = data[key as keyof FormParams];
      const profileValue = profile
        ? profile[key as keyof ProfileRes] ||
          defaultProfileFormValues[key as keyof FormParams]
        : defaultProfileFormValues[key as keyof FormParams];
      if (JSON.stringify(dataValue) !== JSON.stringify(profileValue)) {
        dirtyFields[key as string] = dataValue;
      }
    });
    return dirtyFields;
  };

  const prepareUpdatedData = (data: FormParams): ProfileNewDataObject => {
    const newData: ProfileCreateParams = {
      name: data.name as string,
    };
    const currentDate = new Date().toISOString();
    if (isNewProfile) {
      newData.created_at = currentDate;
      newData.updated_at = currentDate;
    } else {
      newData.updated_at = currentDate;
    }

    if (oldProfileImage !== profileImage) {
      newData.image = profileImage;
    }
    Object.keys(getDirtyFields(data)).forEach((key) => {
      newData[key] = data[key as keyof ProfileRes];
    });
    if (editorState.raw_page_content) {
      newData.raw_page_content = editorState.raw_page_content;
    }
    const parsedContent = parseJson(editorState.page_content);

    return {
      data: newData,
      content: parsedContent,
    };
  };

  const onSubmit = async (data: FormParams) => {
    setIsSave(true);
    const newDataObj = prepareUpdatedData(data);
    if (isNewProfile) {
      const res = await dispatch(createProfile(newDataObj));
      if (!res.type.includes("rejected")) {
        const id = (res?.payload as ProfileCreateRes)?.profile_id;
        navigate(`/${ROUTER_PATHS.profile}/${id}`);
        setIsEditing(false);
        updateCategoriesCount(newDataObj.data, () =>
          dispatch(getCategory(favorites))
        );
        setIsSave(false);
        return;
      } else {
        setIsSave(false);
        setError("name", { message: "Name is already exist" });
        return;
      }
    } else {
      const res = await dispatch(
        updateProfileData({
          id,
          newDataObj,
        })
      );

      if (!res.type.includes("rejected")) {
        updateCategoriesCount(newDataObj.data, () =>
          dispatch(getCategory(favorites))
        );
      } else if (
        typeof res.payload === "string" &&
        res.payload.includes("409")
      ) {
        setIsSave(false);
        setError("name", { message: "Name is already exist" });
        return;
      }
    }
    setIsSave(false);
    setIsEditing(false);
  };

  const onReset = () => {
    updateEditor(profileEditorState);
    setIsEditing(false);
    setProfileImage(oldProfileImage);
    if (isNewProfile) {
      navigate(`${ROUTER_PATHS.main}`);
    } else {
      clearErrors();
      Object.keys(touchedFields).forEach((key) => {
        setValue(key as keyof FormParams, profile![key as keyof FormParams]);
      });
    }
  };

  const time = profile
    ? formatDate(profile.updated_at ?? profile.created_at)
    : "";

  return (
    <div className={styles.profileContainer}>
      <Loader
        className="content"
        skipWait
        show={(!isProfileData && !isNewProfile) || isSave}
      />
      {isUserData && <Breadcrumbs width="1040px" pageName="Profile" />}
      {profile && isUserData && (
        <div
          className={`${styles.profileWrapper} ${
            isSave ? styles.pointerEventNone : ""
          }`}
        >
          <ConditionalWrap
            condition={isEditing}
            wrap={(items: any) => (
              <form
                className="w100"
                onSubmit={handleSubmit(onSubmit)}
                onReset={onReset}
              >
                {items}
              </form>
            )}
          >
            <ProfileHeader
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              time={time}
            />
            <div className={styles.profileInfo}>
              <ProfileImage
                url={profileImage || defaultImage}
                alt={profile.name}
                isProfileEditing={isEditing}
                onPhotoUpdate={(img: string) => setProfileImage(img)}
                onCancelUpdate={() => setProfileImage(oldProfileImage)}
                onPhotoRemove={() => setProfileImage("")}
              />
              {isEditing ? (
                <PreContentForm control={control} errors={errors} />
              ) : (
                <ProfileInfo profile={profile} />
              )}
            </div>
            <div className={isEditing ? styles.editorWrapper : undefined}>
              <Editor
                editable={isEditing}
                editorState={editorState.editor_state}
                editorKey={editorKey}
                onStateChange={(obj: EditorStateChangeProps) =>
                  setEditorState(obj)
                }
              />
            </div>
          </ConditionalWrap>
        </div>
      )}
    </div>
  );
};
