const styles = {
  MuiBackdrop: {
    variants: [
      {
        props: { invisible: false },
        style: {
          backgroundColor: "var(--backdrop-color)",
        },
      },
    ],
  },
};

export default styles;
