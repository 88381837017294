import { favoritesSelector } from "@app-redux/selectors";
import { useAppDispatch, useAppSelector } from "@app-redux/store";
import { getCategory } from "@app-redux/thunk/categoryThunk";
import { deleteProfileThunk } from "@app-redux/thunk/profileThunk";
import { ROUTER_PATHS } from "@constants/index";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

export const ProfileMenu = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(favoritesSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const openMenu = Boolean(anchorEl);

  const deleteConfirm = async () => {
    const res = await dispatch(deleteProfileThunk(id));
    await dispatch(getCategory(favorites));

    if (!res.type.includes("rejected")) {
      setOpen(false);
      setAnchorEl(null);
      navigate(ROUTER_PATHS.main);
    }
  };

  return (
    <>
      <IconButton
        className={styles.iconButton}
        aria-label="Menu"
        color="inherit"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
        aria-controls={openMenu ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpen(true);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon color="inherit" />
          </ListItemIcon>
          Delete Profile
        </MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This profile will be permanently deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
              setAnchorEl(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={deleteConfirm}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
