const styles = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: "var(--text-title-2)",
        padding: "24px 24px 16px 24px",
        fontWeight: "normal",
        fontSize: "1.5rem",
        lineHeight: "2rem",
        letterSpacing: "0",
      },
    },
  },
};

export default styles;
