const styles = {
  MuiSelect: {
    styleOverrides: {
      root: {
        cursor: "pointer",
        borderRadius: "12px",
        lineHeight: "1.5",
        letterSpacing: "0.5px",
        color: "var(--text-title-1)",
        ".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
          padding: "8px 32px 8px 16px",
        },
        ".MuiOutlinedInput-notchedOutline": {
          top: "-2px",
        },
        ".MuiSelect-select.MuiInputBase-input": {
          padding: "0",
          minHeight: "1.5em",
        },
        ".MuiSelect-select > svg": {
          display: "none",
        },
        ".MuiMenu-paper": {
          borderRadius: "12px",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.08)",
        },
        ".MuiList-root": {
          padding: "0",
        },
        ".MuiMenuItem-root": {
          minHeight: "56px",
          color: "var(--text-title-1)",
        },
        ".MuiMenuItem-root > svg": {
          marginRight: "16px",
        },
        ".MuiMenuItem-root.Mui-selected": {
          backgroundColor: "var(--background-secondary)",
        },
        ".MuiModal-backdrop": {
          backgroundColor: "var(--backdrop-color-multi-select)",
        },
      },
    },
  },
};

export default styles;
