import { CircularProgress } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";

const LOADER_DELAY = 400;

type Props = {
  className?: "content" | "profileList";
  show: boolean;
  skipWait?: boolean;
};

export const Loader = ({ className, show, skipWait }: Props) => {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!show || skipWait) {
      setState(show);
    } else {
      timer = setTimeout(() => {
        setState(show);
      }, LOADER_DELAY);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show]);

  return state ? (
    <div className={`${styles.loader} ${className ? styles[className] : ""}`}>
      <CircularProgress />
    </div>
  ) : (
    <></>
  );
};
