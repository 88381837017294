const styles = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "100px",
        minWidth: "56px",
        padding: "6px 12px",
        textTransform: "none",
        lineHeight: "142.857%",
        fontSize: "var(--text-body-1)",
        "& ,&:hover": {
          boxShadow: "none",
        },
        "&.MuiButton-containedInherit": {
          backgroundColor: "rgb(245 245 245)",
        },
        "&.MuiButton-containedInherit:hover": {
          backgroundColor: "rgb(224 224 224)",
        },
        "&.MuiButton-textSecondary": {
          color: "var(--gray-5)",
        },
        "&.MuiButton-textSecondary:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};

export default styles;
